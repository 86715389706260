import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from "../../../../app/service/subscription.service";

export const PaymentlistData = () => {
    const [paymentList, setPaymentList] = useState<any>([]);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    const [loading, setLoading] = useState(false);
    const history = useHistory();



    useEffect(() => {
        getPayments();

    }, []);

    const getPayments = async () => {
        setLoading(true);
        try {
            const res: any = await GET_STUDENT_SUBSCRIPTION_PRICE_LIST("", 0);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res?.rows?.filter((data: any) => data?.code === "ANNUAL_MEMBERSHIP_AMOUNT");
                    data.forEach((element: any) => {
                        element.name = capitalizeWords(element?.name);
                        element.membership_amount = element?.data?.membership_amount;
                        element.price_per_day = (element?.data?.membership_amount / 365);
                        element.notes = element?.data?.notes;
                        element.discount_percentage = element?.data?.discount_percentage;
                        element.final_price = element?.data?.membership_amount * (100 - parseInt(element?.data?.discount_percentage)) / 100;
                        element.discount_price_per_day = (element?.final_price / 365);
                    });
                    setPaymentList(data);
                }
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }
    function onClickPay(data: any) {
        history.push(`/institute-dashboard/manage-school/list/${id}/payment-list/${schoolsCode}`)
    }
    const capitalizeWords = (name: string): string => {
        return name
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');
    };
    return (
        <div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="table-responsive">
                    <table className="table table_new_ui">
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th >Subscription</th>
                                <th>Price / Student</th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentList.map((data: any, i: number) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{data.name}</td>
                                    <td>{data.membership_amount}</td>
                                    <td>
                                        <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay(data)}>Select Student List</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {paymentList?.length === 0 && <p className="f14 text-center mt-2">No Data Available</p>}
                </div>}
        </div>
    )
}