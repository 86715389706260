import React from 'react'
import { Switch, Route } from 'react-router-dom';
import TeacherDashboardView from '../../pages/teacher-dashboard/dashboard/view';
import AbhyaasTeacherInitiative from '../../pages/teacher-dashboard/dashboard/abhyaas/teacher-initiative';
import AbhyaasTeacherClasswiseReport from '../../pages/teacher-dashboard/dashboard/abhyaas/class-dashboard';
import AbhyaasTeacherClassStudentList from '../../pages/teacher-dashboard/dashboard/abhyaas/class-students-list';
import AbhyaasTeacherSubjectStudentList from '../../pages/teacher-dashboard/dashboard/abhyaas/subject-students-list';
import AbhyaasTeacherSubjectList from '../../pages/teacher-dashboard/dashboard/abhyaas/subject-list';
import AbhyaasTeacherSubjectReport from '../../pages/teacher-dashboard/dashboard/abhyaas/subject-dashboard';
import TeacherViewStudentAbhyaasSummary from '../../pages/teacher-dashboard/dashboard/abhyaas/summary';
import TeacherViewStudentAbhyaasResult from '../../pages/teacher-dashboard/dashboard/abhyaas/result';
import ParikshaTeacherInitiative from '../../pages/teacher-dashboard/dashboard/pariksha/teacher-initiative';
import ParikshaTeacherClasswiseReport from '../../pages/teacher-dashboard/dashboard/pariksha/class-dashboard';
import ParikshaTeacherSubjectReport from '../../pages/teacher-dashboard/dashboard/pariksha/subject-dashboard';
import ParikshaTeacherClassStudentList from '../../pages/teacher-dashboard/dashboard/pariksha/class-students-list';
import ParikshaTeacherSubjectStudentList from '../../pages/teacher-dashboard/dashboard/pariksha/subject-students-list';
import ParikshaTeacherSubjectList from '../../pages/teacher-dashboard/dashboard/pariksha/subject-list';
import TeacherViewStudentParikshaTestAnalysis from '../../pages/teacher-dashboard/dashboard/pariksha/test-analysis';
import TeacherVieStudentParikshaExplanation from '../../pages/teacher-dashboard/dashboard/pariksha/explanation';
import TeacherViewStudentParikshaSummary from '../../pages/teacher-dashboard/dashboard/pariksha/summary';
import TeacherViewProfile from '../../pages/teacher-dashboard/profile/view';


export const TeacherDashBoardRoutes = () => {
    return (
        <Switch>
            <Route exact path="/teacher-dashboard/view" component={TeacherDashboardView}></Route>

            {/* profile */}
            <Route exact path="/teacher-dashboard/profile/view" component={TeacherViewProfile}></Route>

            {/* Abhyaas */}
            <Route exact path="/teacher-dashboard/view/abhyaas/teacher-initiatives" component={AbhyaasTeacherInitiative}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/class-dashboard" component={AbhyaasTeacherClasswiseReport}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/subject-dashboard" component={AbhyaasTeacherSubjectReport}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/class-students" component={AbhyaasTeacherClassStudentList}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/subject-students" component={AbhyaasTeacherSubjectStudentList}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/subjects-list" component={AbhyaasTeacherSubjectList}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/summary/:moduleUUID/:userCode/:schoolCode/:BoardUuid/:MediumUuid/:StandardUuid/:SubjectUuid/:teacherType" component={TeacherViewStudentAbhyaasSummary}></Route>
            <Route exact path="/teacher-dashboard/view/abhyaas/result/:moduleUUID/:userCode/:schoolCode/:teacherType" component={TeacherViewStudentAbhyaasResult}></Route>
            {/* Pariksha */}
            <Route exact path="/teacher-dashboard/view/pariksha/teacher-initiatives" component={ParikshaTeacherInitiative}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/class-dashboard" component={ParikshaTeacherClasswiseReport}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/subject-dashboard" component={ParikshaTeacherSubjectReport}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/class-students" component={ParikshaTeacherClassStudentList}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/subject-students" component={ParikshaTeacherSubjectStudentList}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/subjects-list" component={ParikshaTeacherSubjectList}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/test-analysis/:moduleUUID/:userCode/:schoolCode/:teacherType" component={TeacherViewStudentParikshaTestAnalysis}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/explanation/:moduleUUID/:userCode/:schoolCode/:teacherType" component={TeacherVieStudentParikshaExplanation}></Route>
            <Route exact path="/teacher-dashboard/view/pariksha/summary/:moduleUUID/:userCode/:schoolCode/:BoardUuid/:MediumUuid/:StandardUuid/:SubjectUuid/:teacherType" component={TeacherViewStudentParikshaSummary}></Route>
        </Switch>
    )
}
