import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormBuilder from "../../../../components/form-builder";
const ParikshaTeacherSubjectList = () => {
    const teacherInfo: any = JSON.parse(localStorage.getItem("teacher-info") || '{}');
    const [formData, setFormData] = useState<any>({});
    const [divisionList, setDivisionList] = useState<any>([]);
    const history = useHistory();

    const handleInput = (data: any) => {
        data = { ...data.value };
        setFormData(data);

    };
    const handleBack = () => {
        history.push(`/teacher-dashboard/view/pariksha/teacher-initiatives`);
    }
    const onClickDashboard = () => {
        if (formData?.subject && formData?.standard && formData?.division) {
            localStorage.setItem("selected_subject_info", JSON.stringify(formData));
            history.push(`/teacher-dashboard/view/pariksha/subject-dashboard`);
        }
    }
    const handleStandardChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedStandard = e.target.value;
        const standardObject = teacherInfo.teaching_standards.find(
            (standard: any) => getStandard(standard.label) === parseInt(selectedStandard)
        );

        if (standardObject?.divisions && standardObject.divisions.length > 0) {
            setDivisionList(standardObject.divisions);
        } else {
            setDivisionList([]);
        }
    };
    const getStandard = (standard: string): number | undefined => {
        switch (standard) {
            case "SIX":
                return 6;
            case "SEVEN":
                return 7;
            case "EIGHT":
                return 8;
            case "NINE":
                return 9;
            case "TEN":
                return 10;
            case "ELEVEN":
                return 11;
            case "TWELVE":
                return 12;
            default:
                return undefined;
        }
    };
    const enableButton = () => {
        if (formData?.subject && formData?.standard && formData?.division) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                    <li className="breadcrumb-item "><Link to="/teacher-dashboard/view"><span className='pe-1'>Dashboard</span></Link></li> /
                    <li className="breadcrumb-item "><Link to="/teacher-dashboard/view/pariksha/teacher-initiatives">Pariksha</Link></li> /
                    <li className="breadcrumb-item ">Subject teacher</li>
                </ol>
            </nav>
            <div className="text-end me-md-5 me-3">
                <button className='btn btn-primary px-4 f14 py-1' onClick={handleBack}>Back</button>
            </div>
            <h5 className="mb-0 f16 text-center mt-1">PARIKSHA DASHBOARD</h5>
            <div className="row pt-3 mt-5 justify-content-center">
                <div className="col-11 col-md-12 col-lg-6">
                    <FormBuilder onUpdate={handleInput}>
                        <form>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
                                    <select className="form-select form-select-lg ms-1 select_subject_teacher" name="subject" value={formData?.subject}>
                                        <option value={""}>Select Subject</option>
                                        {teacherInfo?.teaching_subjects?.map((subjects: any, z: number) => (
                                            <option key={z} value={subjects?.value}>{subjects?.value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
                                    <select className="form-select form-select-lg ms-1 select_subject_teacher" name="standard" value={formData?.standard} onChange={handleStandardChange} >
                                        <option value={""}>Select Standard</option>
                                        {teacherInfo?.teaching_standards?.map((standard: any, i: number) => (
                                            <>
                                                <option key={i} value={getStandard(standard?.label)} >{getStandard(standard?.label)}</option>
                                            </>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-4 mt-3">
                                    <select className="form-select form-select-lg ms-1 select_subject_teacher" name="division" value={formData?.division}>
                                        <option value={""}>Select Division</option>
                                        {divisionList?.map((division: any, i: number) => (
                                            <option value={division?.value}>{division?.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                </div>
            </div>
            <div className="text-center mt-5 pt-5" >
                <span className={` acc_dashboard  ${enableButton() ? "cursor-pointer" : "text-secondary"} `} onClick={onClickDashboard}>Access Dashboard</span>
            </div>
        </div >
    )
}
export default ParikshaTeacherSubjectList;