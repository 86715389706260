import { Switch, Route, Redirect } from "react-router";
import { IndividualStudentForm } from "./form";
import { IndividualStudentsList } from "./list";
import { IndividualStudentInFo } from "./student-info";
import IndividualStudentPaymentView from "./payment-view";
import { IndividualUserPaymentList } from "./payment/list";


interface Props {
    match?: any;
}
const IndividualStudentRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }


    return (
        <Switch>
            <Route exact path={`${url}/list`}>
                <IndividualStudentsList></IndividualStudentsList>
            </Route>
            <Route exact path={`${url}/form/:id`} >
                <IndividualStudentForm></IndividualStudentForm>
            </Route>
            <Route exact path={`${url}/form`} >
                <IndividualStudentForm></IndividualStudentForm>
            </Route>
            <Route path={`${url}/info/:id`} exact>
                <IndividualStudentInFo></IndividualStudentInFo>
            </Route>
            <Route path={`${url}/info/:id/parentlist/:userCode/:schoolName`} exact>
                <IndividualStudentInFo></IndividualStudentInFo>
            </Route>
            <Route path={`${url}/info/:id/parentform/:userCode/:schoolName/:parentId`} exact>
                <IndividualStudentInFo></IndividualStudentInFo>
            </Route>
            <Route path={`${url}/info/:id/modulelist/:userCode/:schoolName`} exact>
                <IndividualStudentInFo></IndividualStudentInFo>
            </Route>
            <Route path={`${url}/info/:id/paymentlist/:userCode/:schoolName`} exact>
                <IndividualStudentInFo></IndividualStudentInFo>
            </Route>
            <Route path={`${url}/info/:id/payment/:userCode/:schoolName`} exact>
                <IndividualStudentPaymentView></IndividualStudentPaymentView>
            </Route>
        </Switch>
    );
}
export default IndividualStudentRoutes;