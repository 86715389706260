import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LOOK_UP_DATA } from '../../../../app/service/lookup.service';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../../app/service/school.service';
import { GET_USER_BY_ID, UPDATE_USER } from '../../../../app/service/users.service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import { toast } from 'react-toastify';
import { CREATE_ASSIGN_USER, GET_MODULES_BY_SCHOOL_ID, GET_MODULES_BY_USER_CODES, GET_TOTAL_MODULES } from '../../../../app/service/module.service';
import moment from 'moment';
import errorMessages from '../../../../components/error-messages';
import "react-datepicker/dist/react-datepicker.css";
import { DateComponent } from '../../../../components/date';
import ProfileImageCrop from '../../../../components/image-crop/crop';
import { MultiSelect } from 'react-multi-select-component';
import CustomMultiSelector from '../../../../components/multi-selector/custom-selector';
import { formatDate } from '../../../../assets/canvasjs.min';

export const StudentsForm = () => {
  const [studentsData, setStudentsData] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
  const [assignUserValidationErrors, setAssignUserValidationErrors] = useState<FormControlError[]>([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [currentStudentData, setCurrentStudentData] = useState<any>({});
  const [statesData, setStatesData] = useState<any>([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [canShowPopup, setCanShowPopup] = useState(false);
  const [canShowUserPopup, setCanShowUserPopup] = useState(false);
  const [assignUserData, setAssignUserData] = useState<any>({});
  const [moduleData, setModuleData] = useState<any>([]);
  const [subModuleData, setSubModuleData] = useState<any>([]);
  const [moduleVersionData, setModuleVersionData] = useState<any>([]);
  const [targetData, setTargetData] = useState<any>([]);
  const [userCode, setUserCode] = useState<any>('');
  const [studentLogo, setStudentLogo] = useState<any>();
  const [imageLoader, setImageLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState(false);
  const [pincodeNumber, setPincodeNumber] = useState(false);
  const [studentInfo, setStudentInfo] = useState<any>({});
  const [email, setEmail] = useState(false);
  const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(null);
  const [moduleList, setModuleList] = useState<any[]>([]);
  const [moduleSelected, setModuleSelected] = useState<any>([]);
  const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
  let { id, schoolCode, userId } = useParams<{ id: string, schoolCode: string, userId: string }>();
  const schoolsCode = schoolCode;
  const usersId: number = parseInt(userId);
  const [nameError, setNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [isDateError, setIsDateError] = useState<any>({});
  const [allStateBlock, setAllStateBlock] = useState<any>({});
  const [blockData, setBlockData] = useState<any>([]);
  const [trailStartDate, setTrailStartDate] = useState<any>();
  const [trailEndDate, setTrailEndDate] = useState<any>();
  const history = useHistory();
  let userInfo: any = "";
  const formValidations = [
    new FormField('name', [FormValidators.REQUIRED]),
    new FormField('date_of_birth', []),
    new FormField('gender', [FormValidators.REQUIRED]),
    new FormField('standard', [FormValidators.REQUIRED]),
    new FormField('division', [FormValidators.REQUIRED]),
    new FormField('medium', [FormValidators.REQUIRED]),
    new FormField('email_id', [FormValidators.REQUIRED]),
    new FormField('mobile_number', [FormValidators.REQUIRED]),
    new FormField('whatsapp_number', []),
    new FormField('blood_group', []),
    new FormField('address', [FormValidators.REQUIRED]),
    new FormField('state', [FormValidators.REQUIRED]),
    new FormField('district', [FormValidators.REQUIRED]),
    new FormField('taluka', []),
    new FormField('city_village', []),
    new FormField('pin_code', [FormValidators.REQUIRED]),
    new FormField('date_of_registration', []),
  ];
  const assignUserFormValidations = [
    new FormField('module_code', [FormValidators.REQUIRED]),
    new FormField('sub_module', [FormValidators.REQUIRED]),
    new FormField('module_ver', [FormValidators.REQUIRED]),
    new FormField('target_std', [FormValidators.REQUIRED]),
  ];
  const userRole = localStorage.getItem("user_role");
  const mediums = [{
    "name": "English",
    "code": "ENGLISH"
  }, {
    "name": "Hindi",
    "code": "HINDI"
  }, {
    "name": "Marathi",
    "code": "MARATHI"
  },
  {
    "name": "Gujarati",
    "code": "GUJARATI"
  }
  ];

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = async () => {
    setLoading(true);
    await getModulesBySchoolCode();
    if (usersId === 0) {
      await fetchInitialDataForNewUser();
    } else {
      await fetchDataForExistingUser();
    }
    await fetchLookUpData();
    await getAllModules();
    setLoading(false);
  };

  const fetchInitialDataForNewUser = async () => {
    const startDate = moment(new Date()).format('YYYY-MM-DD');
    onSelectStartDate(startDate); // Ensure onSelectStartDate is defined elsewhere
    setStudentsData({
      value: {
        account_type: "TRIAL",
      },
    });
    currentStudentData.account_type = "TRIAL";
  };

  const fetchDataForExistingUser = async () => {
    try {
      const response: any = await GET_USER_BY_ID(usersId);
      if (response.status === 'fail') {
        toast.error(response.message);
      } else {
        const data = response.rows[0];
        processUserData(data);
      }
    } catch (error) {
      console.error("Error fetching user by ID:", error);
    }
  };

  const processUserData = (data: any) => {
    if (data.date_of_birth) {
      const dateOfBirth: any = new Date(data.date_of_birth);
      setStartDate(dateOfBirth);
    }
    if (data.date_of_registration) {
      const dateOfRegistration = new Date(data.date_of_registration);
      setTodayDate(dateOfRegistration);
    }
    if (data.trial_start_dt && data.trial_end_dt) {
      setTrailStartDate(moment(data.trial_start_dt.split("T")[0]).format('YYYY-MM-DD'));
      setTrailEndDate(moment(data.trial_end_dt.split("T")[0]).format('YYYY-MM-DD'));
    }
    if (data.mobile_number === data.whatsapp_number) {
      data.checked = true;
    }
    if (data.access) {
      setCheckedUserMechanismStatus(data.access);
    }
    userInfo = { ...data }
    setCurrentStudentData(data);
    setUserCode(data.user_code);
    setStudentLogo(data.profile_img);
    // getModulesByUserCode(data.user_code);

    if (data.modules) {
      const moduleArray = data.modules.split(',');
      const preData = moduleArray.map((module: string) => ({
        label: module,
        value: module,
      }));
      setModuleSelected(preData);
    }
  };

  const fetchLookUpData = async () => {
    try {
      const lookupData: any = await LOOK_UP_DATA();
      if (lookupData.status === 'fail') {
        toast.error(lookupData.message);
      } else {
        setStatesData(lookupData.states);
        if (usersId > 0) {
          getStatesData(lookupData, userInfo); // Ensure getStatesData is defined elsewhere
        }
      }
    } catch (error) {
      console.error("Error fetching lookup data:", error);
    }
  };


  const getModulesBySchoolCode = async () => {
    try {
      const res: any = await GET_MODULES_BY_SCHOOL_ID(schoolsCode)
      if (res.status === 'fail') {
        toast.error(res.message);
      } else {
        if (res) {
          const data = res.rows;
          const preData = data.filter((el: any) => el.module_code !== "CAS-PARENT");
          const preparedData: React.SetStateAction<any[]> = [];
          for (let i = 0; i < preData?.length; i++) {
            preparedData.push({
              label: preData[i].module_code,
              value: preData[i].module_code,

            })
          }
          setModuleList(preparedData);
          setModuleSelected(preparedData);

        }
      }
    } catch (error) {
      throw error;
    }
  }

  const getAllModules = async () => {
    try {
      const res: any = await GET_TOTAL_MODULES();
      if (res) {
        const data = res.rows;
        setModuleData(data);
      }
    } catch (error) {
      throw error;
    }
  }
  const getModulesByUserCode = async (user_code: string) => {
    try {
      const res: any = await GET_MODULES_BY_USER_CODES(user_code)
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
        } else {
          let data = res?.filter((item: any) => item?.module_code !== 'CAS-PARENT');
          const preparedData = [];
          for (let i = 0; i < data?.length; i++) {
            preparedData.push({
              label: data[i].module_code,
              value: data[i].module_code,
              disabled: data[i].amount_status === "COMPLETED" ? true : false
            })
          }
          setModuleList(preparedData);
        }
      }

    } catch (error) {
      throw error;
    }

  }

  function getStatesData(data1: any, data: any) {
    if (data1.states.length > 0) {
      const preparedData = data1.states.filter((el: any) => el.state === data.state);
      if (preparedData.length > 0) {
        setDistrictData(preparedData[0].districts);
        onLoadBlocks(data.district, preparedData[0]?.block);
      }

    }
  }

  const handleInput = (data: any) => {
    data.value = { ...currentStudentData, ...data.value };
    setStudentsData(data);
    const errors: any = FormValidator(formValidations, data.value);
    setValidationErrors(errors);

  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    let moduleCodes = moduleSelected?.map((item: any) => item.value).join(',');
    const data = studentsData.value ? { ...studentsData.value } : { ...currentStudentData };

    // return
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    data.type = "school";
    data.user_role = "STUDENT";
    data.institute_school_code = schoolsCode;
    data.profile_img = studentLogo;
    data.date_of_registration = todayDate ? todayDate : null;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    data.account_type = data?.account_type ? data?.account_type : "PAID";
    data.trial_start_dt = data?.account_type === "TRIAL" ? trailStartDate : null;
    data.trial_end_dt = data?.account_type === "TRIAL" ? trailEndDate : null;
    data.modules = data?.account_type === "TRIAL" ? moduleCodes : null;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.month;
    delete data.year;
    delete data.day;
    delete data.checked;
    if (errors.length < 1 && !email && !mobileNumber && !pincodeNumber && onChangeAddress(data.address)
      && onChangeName(data.name)
      && (data?.account_type === "PAID" || (data?.account_type === "TRIAL" && trailStartDate && trailEndDate && moduleSelected.length > 0))
    ) {
      data.whatsapp_number = data.whatsapp_number ? data.whatsapp_number : data.mobile_number;
      if (data.date_of_birth !== null) {
        setLoading(true);
        let preData = removeEmptyStringKeys(data);
        CREATE_SCHOOL_USER(preData).then((res: any) => {
          if (res.status === 'fail' || res.errorCode === 400) {
            toast.error(res.message || res.errorMsg);
            setLoading(false);
            setCurrentStudentData(data);
          } else {
            toast.success("Students Created Successfully");
            setLoading(false);
            if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
              history.push(`/institute-dashboard/manage-school/list/info/${id}/studentslist/${schoolsCode}`);

            }
            else if (userRole === "SCHOOL_ADMIN" || userRole === "VICE_PRINCIPAL" || userRole === "PRINCIPAL") {
              history.push(`/school-dashboard/profile/students/list/${id}/${schoolsCode}`)
            }
            else {
              history.push(`/dashboard/school/info/${id}/studentslist/${schoolsCode}`);
            }
            setStudentInfo(res);
            setCanShowUserPopup(true);
          }
        });
      }
    }
    else {

      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
      if (!addressError && !onChangeAddress(data.address)) {
        setAddressError('Please enter address');
      }
    }

  }

  function updateStudent() {
    setIsFormSubmitted(true);
    let moduleCodes = moduleSelected?.map((item: any) => item.value).join(',');
    const data = studentsData.value ? { ...studentsData.value } : { ...currentStudentData };
    // setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    // setPrincipalValidationErrors(errors);
    data.type = "school";
    data.user_role = "STUDENT";
    data.institute_school_code = schoolsCode;
    data.id = usersId;
    data.profile_img = studentLogo;
    data.date_of_birth = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    data.date_of_registration = todayDate;
    data.account_type = data?.account_type ? data?.account_type : "PAID";
    data.trial_start_dt = data?.account_type === "TRIAL" ? trailStartDate : null;
    data.trial_end_dt = data?.account_type === "TRIAL" ? trailEndDate : null;
    data.modules = data?.account_type === "TRIAL" ? moduleCodes : null;
    delete data.sameMobileNumber;
    delete data.file;
    delete data.checked;
    delete data.user_code;
    delete data.teaching_standards;
    delete data.teaching_subjects;
    delete data.address2;
    delete data.status;
    delete data.guardian;
    delete data.occupation;
    delete data.post_in;
    delete data.active;
    delete data.cas_report_view;
    delete data.indv_school_details;
    delete data.created_at;
    delete data.updated_at;
    delete data.role_name;
    delete data.user_name;
    delete data.short_name;
    delete data.name_of_school;
    delete data.name_of_organization;
    delete data.display_name;
    delete data.day;
    delete data.month;
    delete data.year;
    delete data.access;
    data.whatsapp_number = currentStudentData.whatsapp_number;
    if (errors.length < 1 && !email && !mobileNumber && !pincodeNumber && onChangeAddress(data.address)
      && onChangeName(data.name)
      && (data?.account_type === "PAID" || (data?.account_type === "TRIAL" && trailStartDate && trailEndDate && moduleSelected.length > 0))
    ) {      // if (errors.length < 1) {
      data.access = checkedUserMechanismStatus;
      setLoading(true);
      let preData = removeEmptyStringKeys(data);
      UPDATE_USER(preData).then((res: any) => {
        if (res.status === 'fail') {
          toast.error(res.message);
          setLoading(false);
        } else {
          toast.success("Students Updated Successfully");
          setLoading(false);
          if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {
            history.push(`/institute-dashboard/manage-school/list/info/${id}/studentslist/${schoolsCode}`);

          } else if (userRole === "SCHOOL_ADMIN" || userRole === "VICE_PRINCIPAL" || userRole === "PRINCIPAL") {
            history.push(`/school-dashboard/profile/students/list/${id}/${schoolsCode}`)
          }
          else {
            history.push(`/dashboard/school/info/${id}/studentslist/${schoolsCode}`);
          }
        }
      });
    } else {

      if (!nameError && !onChangeName(data.name)) {
        setNameError('Please enter name');
      }
      if (!addressError && !onChangeAddress(data.address)) {
        setAddressError('Please enter address');
      }
    }
    // }
  }

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  }

  const handleStateChange = (e: any) => {
    const data = { ...studentsData.value };
    data.state = e.target.value;
    const preparedData = statesData.filter((el: any) => el.state === e.target.value);
    setDistrictData(preparedData[0].districts);
    setAllStateBlock(preparedData[0]?.block);

    if (currentStudentData) {
      currentStudentData.state = e.target.value;
    }
  }

  const handleDistrictChange = (e: any) => {
    setBlockData([]);
    const data = { ...studentsData.value };
    data.district = e.target.value;
    if (currentStudentData) {
      currentStudentData.district = e.target.value;
    }
    onLoadBlocks(e.target.value, allStateBlock);
  }


  const onLoadBlocks = (e: any, blocks: any) => {
    Object.keys(blocks).find(key => {
      if (key === e) {
        setBlockData(blocks[key]);
        return;
      }
    });
  }

  const handleBlockChange = (e: any) => {
    const data = { ...studentsData.value };
    data.taluka = e.target.value;
    if (currentStudentData) {
      currentStudentData.taluka = e.target.value;
    }

  }

  const handleGender = (e: any) => {
    const data = { ...studentsData.value };
    data.gender = e.target.value;
    if (currentStudentData) {
      currentStudentData.gender = e.target.value;
    }
  }

  const handleclose = () => {
    setCanShowPopup(false);
  }

  const handleUserclose = () => {
    setCanShowUserPopup(false);
    history.push(`/dashboard/school/info/${id}/studentslist/${schoolsCode}`);
  }

  const handleUserSave = () => {
    setCanShowUserPopup(false);
    history.push(`/dashboard/school/info/${id}/studentslist/${schoolsCode}`);
  }

  const handleAssignUser = () => {
    setCanShowPopup(true);
    setIsFormSubmitted(false);
  }

  const handleModuleChange = (e: any) => {
    setSubModuleData([]);
    const preparedData = moduleData.filter((el: any) => el.module_code === e.target.value);
    setSubModuleData(preparedData);
  }

  const handleSubModuleChange = (e: any) => {
    setModuleVersionData([]);
    const preparedData = moduleData.filter((el: any) => el.sub_module === e.target.value);
    setModuleVersionData(preparedData);
  }

  const handleModuleVersionChange = (e: any) => {
    setTargetData([]);
    const preparedData = moduleData.filter((el: any) => el.module_ver === e.target.value);
    setTargetData(preparedData);
  }

  const handleTargetChange = (e: any) => {
  }

  const handleAssignUserInput = (data: any) => {
    setAssignUserData(data);
    const errors: any = FormValidator(assignUserFormValidations, data.value);
    setAssignUserValidationErrors(errors);
  };

  const getAssignUserInputValid = (control: string) => {
    const value = GetControlIsValid(assignUserValidationErrors, control);
    return value;
  }

  const handleAssignUserSubmit = () => {
    setIsFormSubmitted(true);
    const assignsSchoolData = { ...assignUserData.value };
    const errors: FormControlError[] = FormValidator(assignUserFormValidations, assignsSchoolData);
    setAssignUserValidationErrors(errors);

    if (errors.length < 1) {
      assignsSchoolData.school_code = schoolsCode;
      assignsSchoolData.user_code = userCode;
      CREATE_ASSIGN_USER(assignsSchoolData).then(res => {
        if (res.status === 'fail') {
          toast.error(res.message);
        } else {
          toast.success("Assign User Created Successfully");
          setCanShowPopup(false);
        }
      });
    }
  }

  function handleUploadLogo(e: any, type: string) {
    if (e.target.files && e.target.files[0]) {
      const fileType = e.target.files[0].name.split('.').pop()
      if (fileType == "jpeg" || fileType == "jpg") {
        const formData = new FormData();
        formData.append('file', e.target.files[0], e.target.files[0].name);
        uploadLogo(formData, type);
      } else {
        toast.error("Valid file type .jpg, .jpeg only");
      }
    }
  }

  function uploadLogo(formdata: any, type: string) {
    setImageLoader(true);
    UPLOAD_LOGO(formdata).then((res: any) => {
      if (res.status === 'fail') {
        toast.error(res.message);
        setImageLoader(false);
      } else {
        setStudentLogo(res.url);
        setImageLoader(false);
      }
    });
  }

  const handleBloodGroupChange = (e: any) => {
    const data = { ...studentsData.value };
    data.blood_group = e.target.value;
    if (currentStudentData) {
      currentStudentData.blood_group = e.target.value;
    }
  }

  const handleStandardChange = (e: any) => {
    const data = { ...studentsData.value };
    data.standard = e.target.value;
    if (currentStudentData) {
      currentStudentData.standard = e.target.value;
    }
  }
  const handleMediumChange = (e: any) => {
    const data = { ...studentsData.value };
    data.medium = e.target.value;
    if (currentStudentData) {
      currentStudentData.medium = e.target.value;
    }
  }

  const handleDivisionChange = (e: any) => {
    const data = { ...studentsData.value };
    data.division = e.target.value;
    if (currentStudentData) {
      currentStudentData.division = e.target.value;
    }
  }

  const handleMobileChange = (e: any) => {
    const data = { ...studentsData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.mobile_number = e.target.value;
      setMobileNumber(false);
    } else {
      data.mobile_number = e.target.value;
      setMobileNumber(true);
    }

    if (currentStudentData) {
      currentStudentData.mobile_number = e.target.value.replace(/\D+/g, '');
    }

    if (currentStudentData.checked) {
      currentStudentData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleWhatsappChange = (e: any) => {
    const data = { ...studentsData.value };
    const re = /(6|7|8|9)\d{9}/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(false);
    } else {
      data.whatsapp_number = e.target.value;
      setWhatsappNumber(true);
    }

    if (currentStudentData) {
      currentStudentData.whatsapp_number = e.target.value.replace(/\D+/g, '');
    }
  }

  const handleEmailChange = (e: any) => {
    const data = { ...studentsData.value };
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      data.email_id = e.target.value;
      setEmail(false);
    } else {
      data.email_id = e.target.value;
      setEmail(true);
    }

    if (currentStudentData) {
      currentStudentData.email_id = e.target.value;
    }
  }

  const handlePinCodeChange = (e: any) => {
    const data = { ...studentsData.value };
    const re = /^[0-9\b]+$/;

    if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
      data.pin_code = e.target.value;
      setPincodeNumber(false);
    } else {
      data.pin_code = e.target.value;
      setPincodeNumber(true);
    }

    if (currentStudentData) {
      currentStudentData.pin_code = e.target.value.replace(/\D+/g, '');
    }
  }


  const handleMobileNumberChange = (e: any) => {
    const data = { ...studentsData.value };
    if (e.target.checked) {
      if (data.whatsapp_number) {
        data.whatsapp_number = data.mobile_number;
        currentStudentData.whatsapp_number = data.mobile_number;
      }
      if (data.mobile_number || currentStudentData.mobile_number) {
        currentStudentData.whatsapp_number = currentStudentData.mobile_number;
        setCurrentStudentData(currentStudentData);
      }
      currentStudentData.checked = true;
    } else {
      currentStudentData.checked = false;
    }
  }

  const onChange = (dates: any) => {
    setStartDate(null);
    if (dates.error) {
      setIsDateError(dates);
    } else {
      setStartDate(dates.date);
    }
  };

  const onDateChange = (dates: any) => {
    setTodayDate(todayDate);
    if (dates.error) {
    } else {
      setTodayDate(dates.date);
    }
  };


  const onChangeName = (event: any) => {
    setNameError('');
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.trim())) {
        setNameError('Enter only alphabets');
      } else {
        return true;
      }
    } else {
      setNameError('Please enter name');
    }
  }

  const onChangeAddress = (event: any) => {
    setAddressError('');
    if (event) {
      if (event.trim().length <= 2) {
        setAddressError('Address minimum 3 characters');
      } else if (event.trim().length >= 256) {
        setAddressError('Address maximum 256 characters');

      } else {
        return true;
      }
    } else {
      setAddressError('Please enter Address');
    }
  }


  const handleUserMechanism = (e: any) => {
    if (currentStudentData) {
      const status = e.target.checked === true ? "ENABLED" : "DISABLED";
      currentStudentData.access = status;
      setCheckedUserMechanismStatus(status);
    }
  }
  const handleAccountChange = (e: any) => {
    const data = { ...studentsData.value };
    data.account_type = e.target.value;
    if (e.target.value === "TRIAL") {
      // onSelectStartDate(moment().format("YYYY-MM-DD"))
    }
    if (currentStudentData) {
      currentStudentData.account_type = e.target.value;
    }
  }
  function removeEmptyStringKeys(obj: any) {
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => key !== "")
    );
  }
  function onSelectStartDate(e: any) {
    setTrailStartDate(e);
    let isExpired = e > trailEndDate;
    if (trailEndDate && isExpired) {
      setTrailEndDate("");
    }
    disabledFutureDatesExpire();
    setTrailEndDate(moment(e).add(14, 'days').format("YYYY-MM-DD"));

  }

  const getStartDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    // const month: any = today.getMonth() + 1;
    // const day: any = today.getDate();
    return `${year}-${month}-${day}`;

  };
  const getEndDate = () => {
    const today = new Date(trailStartDate);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  function disabledFutureDatesExpire() {
    var dtToday = trailStartDate ? new Date(trailStartDate) : new Date();
    var month: any = dtToday.getMonth() + 1;
    var day: any = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = day.toString();
    return year + '-' + month + '-' + day;
  }
  return (
    <div>
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      <div className={`modal fade ${canShowUserPopup ? 'show' : 'false'}`} style={{ display: (canShowUserPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Student Login Details</h5>
              <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleUserclose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>Login ID: {studentInfo.user_name}</div>
              <div>Password: {studentInfo.password}</div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleUserclose}>Close</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Assign  User</h5>
              <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FormBuilder onUpdate={handleAssignUserInput}>
                <form>
                  <div className="row custom-form">
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label mb-0">Module Name</label>
                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                        <select name="module_code" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleChange(e) }}>
                          <option value="">Select module</option>
                          {moduleData.map((name: any, i: number) => (
                            <option key={i} value={name.module_code}>
                              {name.module_name}
                            </option>
                          ))}
                        </select>
                        {isFormSubmitted && !getAssignUserInputValid('module_code') && <p className="text-danger">Please fill the field</p>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label mb-0">Sub Module</label>
                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                        <select name="sub_module" className="form-select form-select-lg mb-3" onChange={(e) => { handleSubModuleChange(e) }}>
                          <option value="">Select sub module</option>
                          {subModuleData.map((name: any, i: number) => (
                            <option key={i} value={name.sub_module}>
                              {name.sub_module}
                            </option>
                          ))}
                        </select>
                        {isFormSubmitted && !getAssignUserInputValid('sub_module') && <p className="text-danger">Please fill the field</p>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label mb-0"> Module Version</label>
                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                        <select name="module_ver" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleVersionChange(e) }}>
                          <option value="">Select module version</option>
                          {moduleVersionData.map((name: any, i: number) => (
                            <option key={i} value={name.module_ver}>
                              {name.module_ver}
                            </option>
                          ))}
                        </select>
                        {isFormSubmitted && !getAssignUserInputValid('module_ver') && <p className="text-danger">Please fill the field</p>}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label className="form-label mb-0">Target Standard</label>
                        <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                        <select name="target_std" className="form-select form-select-lg mb-3" onChange={(e) => { handleTargetChange(e) }}>
                          <option value="">Select target standard</option>
                          {targetData.map((name: any, i: number) => (
                            <option key={i} value={name.target_std}>
                              {name.target_std}
                            </option>
                          ))}
                        </select>
                        {isFormSubmitted && !getAssignUserInputValid('target_std') && <p className="text-danger">Please fill the field</p>}
                      </div>
                    </div>
                  </div>
                </form>
              </FormBuilder>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Close</button>
              <button type="button" className="btn btn-primary cursor-pointer" onClick={handleAssignUserSubmit}>Create</button>
            </div>
          </div>
        </div>
      </div>
      {!loading && <div>
        <FormBuilder onUpdate={handleInput}>
          <form>
            <div style={{ borderBottom: 'solid 1px black', marginTop: '15px', paddingLeft: '10px' }} className="mb-4">
              <h5 className="form-label mb-2 d-block">Personal Details</h5>
            </div>
            <div className="row custom-form">
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Miss/ Master
                    <span style={{ paddingLeft: '20px' }}>Name</span></label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="name" onChange={(e) => onChangeName(e.target.value)} defaultValue={currentStudentData.name} placeholder="Please enter name" />
                  {nameError && <p className="text-danger">{nameError}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Date of Birth</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span><br />
                  {currentStudentData.date_of_birth &&
                    <DateComponent onDate={onChange} yearDiff={10} defaultData={currentStudentData.date_of_birth}></DateComponent>}
                  {!currentStudentData.date_of_birth && <DateComponent onDate={onChange} yearDiff={10}></DateComponent>}
                  {isFormSubmitted && !startDate && !isDateError?.error && <p className="text-danger">Please select date</p>}
                  {isDateError?.error && !startDate && <p className="text-danger">{isDateError?.error}</p>}

                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-4">Gender</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <br />
                  <input type="radio" name="gender" value="male" checked={currentStudentData?.gender === "male"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Male</span>
                  <input type="radio" name="gender" value="female" checked={currentStudentData?.gender === "female"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ margin: '0px 12px' }}>Female</span>

                  <input className="mb-0" type="radio" value="transgender" name="gender" checked={currentStudentData?.gender === "transgender"} onChange={(e) => { handleGender(e) }} />
                  <span style={{ paddingRight: '15px', paddingLeft: '15px' }}>Other</span>
                  {isFormSubmitted && !getInputValid('gender') && <p className="text-danger">Please select gender</p>}
                </div>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-1">Blood Group</label>
                  <select name="blood_group" value={currentStudentData.blood_group} onChange={(e) => { handleBloodGroupChange(e) }} className="form-select form-select-lg mb-3">
                    <option value="">Select blood group</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                    <option value="A-">A-</option>
                    <option value="A+">A+</option>
                    <option value="B-">B-</option>
                    <option value="B+">B+</option>
                    <option value="AB-">AB-</option>
                    <option value="AB+">AB+</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Medium</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <select className="form-select form-select-lg mb-3" name="medium" value={currentStudentData.medium} onChange={(e) => { handleMediumChange(e) }}>
                    <option value="">Select medium</option>
                    {mediums?.map((medium: any, i: number) => (
                      <option value={medium?.code} key={i}>{medium?.name}</option>
                    ))}
                  </select>
                  {isFormSubmitted && !getInputValid('medium') && <p className="text-danger">Please select medium</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Standard</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <select className="form-select form-select-lg mb-3" name="standard" value={currentStudentData.standard} onChange={(e) => { handleStandardChange(e) }}>
                    <option value="">Select standard</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  {isFormSubmitted && !getInputValid('standard') && <p className="text-danger">Please select standard</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Division</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <select className="form-select form-select-lg mb-3" name="division" value={currentStudentData.division} onChange={(e) => { handleDivisionChange(e) }}>
                    <option value="">Select division</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                  </select>
                  {isFormSubmitted && !getInputValid('division') && <p className="text-danger">Please select division</p>}
                </div>
              </div>
              {(currentStudentData.date_of_registration && usersId > 0) && <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Date of Registration</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  {currentStudentData.date_of_registration &&
                    <DateComponent onDate={onDateChange} yearDiff={0} idDisable={true} defaultData={currentStudentData.date_of_registration}></DateComponent>}
                </div>
              </div>}
              {usersId > 0 && <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label">User Access</label><br />
                  <label className="switch">
                    <input type="checkbox" name="access" value={currentStudentData?.access} checked={currentStudentData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>}

              {(userRole === "SUPER_ADMIN" || userRole === "SCHOOL_ADMIN") && <>
                <div className='col-md-4 col-12'>
                  <div className='mb-4'>
                    <label className="form-label mb-0">Account Type</label>
                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                    <select className="form-select form-select-lg mb-3" name="account_type" value={currentStudentData.account_type} onChange={(e) => { handleAccountChange(e) }} >
                      <option value="">Select account type</option>
                      <option value="PAID">Paid</option>
                      <option value="TRIAL">Trial</option>
                    </select>
                    {(isFormSubmitted && ((usersId && !currentStudentData?.account_type) || (!usersId && !studentsData?.value?.account_type))) && <p className="text-danger">Please select account type</p>}
                  </div>
                </div>
                {(currentStudentData?.account_type !== "PAID") && <>

                  <div className="col-md-4">
                    <div className="mb-4">
                      <label className="form-label mb-2 f16 "> Modules</label>
                      <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                      <CustomMultiSelector
                        options={moduleList}
                        value={moduleSelected}
                        onChange={setModuleSelected}
                        labelledBy="Select modules"
                        customAllSelectedText="All modules are selected"
                      />

                      {(isFormSubmitted && moduleSelected?.length == 0) && <p className="text-danger mt-2 mb-0">Please select module</p>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4">
                      <label className="form-label mb-2">Trial Start date
                      </label>
                      <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                      <input type="date" className='form-control' name="trial_start_dt" disabled={userRole === "SCHOOL_ADMIN"} value={trailStartDate} min={getStartDate()} onChange={(e: any) => onSelectStartDate(e.target.value)} onKeyDown={(event) => {
                        event.preventDefault();
                      }} />
                      {(isFormSubmitted && !trailStartDate) && <p className="text-danger">Please select start date</p>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4">
                      <label className="form-label mb-2">Trial End date
                      </label>
                      <span style={{ color: 'red', fontSize: '22px', paddingLeft: '5px' }}>*</span>
                      <input type="date" className='form-control' name="trial_end_dt" disabled={(!trailStartDate || userRole === "SCHOOL_ADMIN")} value={trailEndDate} min={getEndDate()} onChange={(e: any) => setTrailEndDate(e.target.value)} onKeyDown={(event) => {
                        event.preventDefault();
                      }} />
                      {(isFormSubmitted && !trailEndDate) && <p className="text-danger">Please select end date</p>}
                    </div>
                  </div>
                </>}

              </>}
              <div className="col-md-4 col-12">
                <div className="mb-4 d-flex justify-content-center">
                  <ProfileImageCrop uploadLogo={uploadLogo} profileImg={studentLogo} imageLoader={imageLoader} profileImageDelete={setStudentLogo} />
                </div>
              </div>
              <div style={{ borderBottom: 'solid 1px black' }} className="mb-4">
                <h5 className="form-label mb-2 d-block">Contact Details</h5>
              </div>

              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Email ID</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="email_id" value={currentStudentData.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                  {email && <p className="text-danger">{errorMessages.email}</p>}
                  {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Mobile Number</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="mobile_number" maxLength={10} onChange={(e) => { handleMobileChange(e) }} value={currentStudentData.mobile_number} placeholder="Please enter mobile number" />
                  {mobileNumber && <p className="text-danger">{errorMessages.mobile_number}</p>}
                  {isFormSubmitted && !getInputValid('mobile_number') && <p className="text-danger">Please enter mobile number</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">WhatsApp Number</label>
                  {/* <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span> */}
                  (  <input type="checkbox" name="sameMobileNumber" checked={currentStudentData.checked} onChange={(e) => { handleMobileNumberChange(e) }} style={{ paddingLeft: '10px' }} />
                  <span style={{ paddingLeft: '10px' }}>Same as Mobile Number</span> )
                  <input className="form-control form-control-lg" type="text" disabled={currentStudentData.checked} name="whatsapp_number" maxLength={10} onChange={(e) => { handleWhatsappChange(e) }} value={currentStudentData.whatsapp_number} placeholder="Please enter whatsapp number" />
                  {(whatsappNumber) && <p className="text-danger">{errorMessages.whatsapp_number}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Address</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="textarea" onChange={(e) => onChangeAddress(e.target.value)} defaultValue={currentStudentData?.address} name="address" placeholder="Please enter address" />
                  {addressError && <p className="text-danger">{addressError}</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">State</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <select name="state" className="form-select form-select-lg mb-3" value={currentStudentData?.state} onChange={(e) => { handleStateChange(e) }}>
                    <option value="">Select state</option>
                    {statesData.map((name: any, i: number) => (
                      <option key={i} value={name.state}>
                        {name.state}
                      </option>
                    ))}
                  </select>
                  {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">District</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <select name="district" className="form-select form-select-lg mb-3" value={currentStudentData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                    <option value="">Select district</option>
                    {districtData.map((name: any, i: number) => (
                      <option key={i} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                  {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">Block</label>
                  <select name="taluka" className="form-select form-select-lg mb-3" value={currentStudentData?.taluka} onChange={(e) => { handleBlockChange(e) }}>
                    <option value="">Select block</option>
                    {blockData.map((name: any, i: number) => (
                      <option key={i} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                  {isFormSubmitted && !getInputValid('taluka') && <p className="text-danger">Please select block</p>}
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">City/Village (Optional)</label>
                  <input className="form-control form-control-lg" type="text" name="city_village" defaultValue={currentStudentData?.city_village} placeholder="Please enter village" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-4">
                  <label className="form-label mb-0">PIN Code</label>
                  <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                  <input className="form-control form-control-lg" type="text" name="pin_code" maxLength={6} onChange={(e) => { handlePinCodeChange(e) }} value={currentStudentData?.pin_code} placeholder="Please enter PIN code" />
                  {pincodeNumber && <p className="text-danger">{errorMessages.pincode}</p>}
                  {isFormSubmitted && !getInputValid('pin_code') && <p className="text-danger">Please enter pin code</p>}
                </div>
              </div>
            </div>
          </form>
        </FormBuilder>
        <div className="form-footer border-top border-primary py-3 text-end mt-4">
          {usersId > 0 && <a className="d-none btn btn-primary btn-sm px-3 rounded-12 float-left cursor-pointer" onClick={handleAssignUser}>Assign To User</a>}&nbsp;
          {usersId === 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Create</a>}
          {usersId > 0 && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => updateStudent()}>Update</a>}
          {userRole !== "SCHOOL_ADMIN" && userRole !== "VICE_PRINCIPAL" && userRole !== "PRINCIPAL" && userRole !== "CHAIRMAN" && userRole !== "GENERAL_SECRETARY" && userRole !== "INSTITUTE_ADMIN" && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/dashboard/school/info/${id}/studentslist/${schoolsCode}`}>Cancel</Link>}
          {(userRole === "SCHOOL_ADMIN" || userRole === "VICE_PRINCIPAL" || userRole === "PRINCIPAL") && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/school-dashboard/profile/students/list/${id}/${schoolsCode}`}>Cancel</Link>}
          {(userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") && <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to={`/institute-dashboard/manage-school/list/info/${id}/studentslist/${schoolsCode}`}>Cancel</Link>}
        </div>
      </div>}
    </div>
  )
}
function addDays(today: Date, arg1: number): any {
  throw new Error('Function not implemented.');
}

