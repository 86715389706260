import { Switch, Route, Redirect } from "react-router";
// import PromotionView from "./view";
import { SpecialDiscountCodeForm } from "./discount/form";
import SpecialDiscountCodeList from "./discount/list";
import SpecialDiscountList from "./list";
import { SpecialDiscountForm } from "./form";



interface Props {
    match?: any;
}
const SpecialDisountRoutes = (props: Props) => {
    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>

            <Route path={`${url}/list`} exact>
                <SpecialDiscountList></SpecialDiscountList>
            </Route>
            <Route path={`${url}/form/:id`} exact>
                <SpecialDiscountForm></SpecialDiscountForm>
            </Route>
            <Route path={`${url}/specialdiscountcode/list/:Uuid`} exact>
                <SpecialDiscountCodeList></SpecialDiscountCodeList>
            </Route>
            <Route path={`${url}/specialdiscountcode/form/:promotionUuid/:Uuid`} exact>
                <SpecialDiscountCodeForm></SpecialDiscountCodeForm>
            </Route>
        </Switch>
    );
}
export default SpecialDisountRoutes;