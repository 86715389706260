

import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "../../../components/data-table";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DELETE_PROMOTIONS, FECTH_FILTER_PROMOTION_DATA_LIST, GET_PROMOTIONS_DATA } from "../../../app/service/promotions";
import { toast } from "react-toastify";
import moment from "moment";
import FormBuilder from "../../../components/form-builder";
import { SpecialDiscountListDataGridCols } from "./data-grid-cols";

function SpecialDiscountList() {
    const [loading, setLoading] = useState(false);
    const [promotionList, setPromotionList] = useState<any>([]);
    const history = useHistory();
    const [activePage, setActivePage] = useState<any>();
    const [pageArray, setPageNumbers] = useState([]);
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get('page');
    const [promotionData, setPromotionData] = useState<any>({});
    const promotionForm = useRef<any>({});

    useEffect(() => {

        getPromotionsList();

    }, []);
    function getPromotionsList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_PROMOTIONS_DATA(activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.discount_type = element.discount_type.replace(/_/g, " ");
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        element.modified_dt = moment(element.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setPromotionList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }
    const onEditPromotion = (data: any) => {
        history.push(`/dashboard/special-discount/form/${data.uuid}`);
    }
    const onDeletePromotion = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_PROMOTIONS(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Promotion Deleted Successfully");
                getPromotionsList();
            }
        });

    }
    const onPageChange = (data: any) => {
        if (data) {
            history.push(`/dashboard/special-discount/list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_PROMOTIONS_DATA(pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.discount_type = element.discount_type.replace(/_/g, " ");
                        element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                        element.modified_dt = moment(element.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                    });
                    setPromotionList(data);
                    setLoading(false);
                }
            }
        });

    }
    function onChangeName(e: any) {

    }
    function handlePromotionTypeChange(e: any) {
        const data = { ...promotionData.value };
        data.promotion_type = e.target.value;
    }
    function handleDiscountTypeChange(e: any) {
        const data = { ...promotionData.value };
        data.discount_type = e.target.value;
    }
    const handlePromotionInput = (data: any) => {
        data.value = { ...data.value };
        setPromotionData(data);
    };
    function filterData() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;

        if (promotionData.value.search || promotionData.value.promotion_type || promotionData.value.discount_type) {

            setLoading(true);
            FECTH_FILTER_PROMOTION_DATA_LIST(activePageNumber, promotionData.value).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                        setLoading(false);
                    } else {
                        setActivePage(currentPageNumber ? currentPageNumber : 1);
                        const data = res.rows;
                        data.forEach((element: any) => {
                            element.discount_type = element.discount_type.replace(/_/g, " ");
                            element.created_dt = moment(element.created_dt.split("T")[0]).format('DD-MM-yyyy');
                            element.modified_dt = moment(element.modified_dt.split("T")[0]).format('DD-MM-yyyy');
                        });
                        setPromotionList(data);
                        const pageNumbers = Math.ceil(res.count / 10);
                        const pageArray: any = [];
                        for (let i = 1; i <= pageNumbers; i++) {
                            pageArray.push(i);
                        }
                        setPageNumbers(pageArray);
                        setLoading(false);
                    }
                }
            });
        }

    }
    function handleRefresh() {
        setPromotionData({});
        getPromotionsList();
        const data = promotionData;
        data.value = {};
        setPromotionData({ ...data });
        promotionForm.current.reset();
    }


    return (
        <div>
            <div className="border-top border-primary py-3 d-flex justify-content-between">
                <h2>Special Discount</h2>
                <p>
                    <Link to={`/dashboard/special-discount/form/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                </p>
            </div>

            <FormBuilder onUpdate={handlePromotionInput}>
                <form ref={promotionForm}>
                    <div className="row ">
                        <div className="col-md-3 ">
                            <div className="mb-4">
                                <label className="form-label mb-0">Promotion Name
                                </label>
                                <input className="form-control form-control-lg f16" type="text" name="search" onChange={(e) => onChangeName(e.target.value)} placeholder="Promotion name" />
                            </div>
                        </div>
                        <div className="col-md-3 ">
                            <div className="mb-4">
                                <label className="form-label mb-0">Promotion Type</label>
                                <select name="promotion_type" className="form-select form-select-lg f16" defaultValue={promotionData?.value?.promotion_type} onChange={(e) => { handlePromotionTypeChange(e) }} >
                                    <option value="">Select the promotion type</option>
                                    <option value="SCHOOL">SCHOOL</option>
                                    <option value="INSTITUTIONAL">INSTITUTIONAL</option>
                                    <option value="GEOGRAPHICAL">GEOGRAPHICAL</option>
                                    <option value="EVENT">EVENT</option>
                                    <option value="SEASONAL">SEASONAL</option>
                                    <option value="MILESTONE">MILESTONE</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                            <div className="mb-4">
                                <label className="form-label mb-0">Discount Type</label>
                                <select className="form-select form-select-lg f16" name="discount_type" onChange={(e) => handleDiscountTypeChange(e)} placeholder="Please select discount type" >
                                    <option value="">Select the discount type</option>
                                    <option value="PERCENTAGE_BASED">PERCENTAGE BASED</option>
                                    <option value="FLAT_VALUE_DISCOUNT">FLAT VALUE DISCOUNT</option>
                                    <option value="VOLUME_BASED">VOLUME BASED</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-3 ">
                            <button className="btn btn-primary mt-4 ms-md-4" type="button" onClick={filterData}>Search</button>
                            <button className="btn clear-btn cursor-pointer mt-4 ms-3" type="button" onClick={() => handleRefresh()}>
                                Clear
                            </button>
                        </div>
                    </div>

                </form>
            </FormBuilder>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={SpecialDiscountListDataGridCols} tableData={promotionList} editInfo={onEditPromotion} deleteInfo={onDeletePromotion}
                activePageNumber={activePage} pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}
export default SpecialDiscountList;