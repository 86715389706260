import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


interface PieChartProps {
    title: string;
    data: { name: string; y: number; color: string }[];
}

const ParikshaWorksheetPieChart: React.FC<PieChartProps> = (props) => {

    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            marginTop: 40,
            height: 290,
            backgroundColor: "transparent",
            events: {
                load() {
                    const chart: any = this;
                    const centerX = chart.series[0].center[0];
                    const rad = chart.series[0].center[2] / 3;

                    Highcharts.each(chart.series[0].data, function (p: any) {
                        const angle = p.angle;
                        const x = centerX + rad * Math.cos(angle);
                        p?.dataLabel?.attr({
                            x: x,
                            'text-anchor': 'center',
                        });
                    });
                },
                redraw() {
                    const chart: any = this;
                    const centerX = chart.series[0].center[0];
                    const rad = chart.series[0].center[2] / 3;

                    Highcharts.each(chart.series[0].data, function (p: any) {
                        const angle = p.angle;
                        const x = centerX + rad * Math.cos(angle);
                        p?.dataLabel?.attr({
                            x: x,
                            'text-anchor': 'center',
                        });
                    });
                },
            },
        },
        title: {
            text: props?.title,
            align: 'center',
            style: {
                color: 'black',
                fontSize: '16px',
                fontWeight: 'normal',
            },
        },
        plotOptions: {
            series: {
                events: {
                    legendItemClick: function (e) {
                        e.preventDefault();
                    }
                },
                borderWidth: 1,
                borderColor: 'white',
            },
            pie: {
                size: '100%',
                dataLabels: {
                    enabled: true,
                    distance: -30,
                    style: {
                        fontWeight: 'normal',
                        fontSize: '13px',
                    },
                    formatter: function () {
                        return `<div >${this?.y?.toFixed(0)}%</div>`;
                    },
                },
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function () {
                            return false; // <== returning false will cancel the default action
                        }
                    }
                },
                allowPointSelect: false,
            },
        },
        legend: {
            symbolWidth: 10,
            symbolHeight: 10,
            symbolRadius: 0,
            symbolPadding: 5,
            y: 5,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y}%`;
            },
        },
        series: [
            {
                type: 'pie',
                data: props?.data,
            },
        ],
        credits: {
            enabled: false,
        },
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )
};

export default ParikshaWorksheetPieChart;
