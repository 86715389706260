import { DataTableCol } from "../../../../components/data-table/types";

export const SubscriptionDormantGridCols: DataTableCol[] = [
    {
        title: "Name",
        control: "name",
        canShowColumn: true,
    },
    {
        title: "Expiration Days",
        control: "days",
        canShowColumn: true,
    },
    {
        title: "Actions",
        control: "both",
        isEdit: true,
        isView: true,
        canShowColumn: true,
    },
];
