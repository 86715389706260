import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_SCHOOL_SUBSCRIPTION_REPORTS = (
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/subscriptions?limit=${10}&page=${pageNumber}&status=active`,
    { params: data }
  );
};

export const GET_STUDENT_SUBSCRIPTION_REPORTS_BY_SCHOOL_CODE = (
  schoolCode: any,
  pageNumber: number,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/user/subscriptions?institute_school_code=${schoolCode}&limit=${10}&page=${pageNumber}&active_users=true&fields=user_code,name,email_id,standard,division,mobile_number`,
    { params: data }
  );
};

export const GET_SCHOOL_SUBSCRIPTION_REPORT_FILTER = (
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/school/subscriptions?limit=${10}&page=${0}&status=active`,
    { params: data }
  );
};

export const GET_STUDENT_SUBSCRIPTION_REPORT_FILTER = (
  schoolCode: any,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/user/subscriptions?institute_school_code=${schoolCode}&limit=${10}&page=${0}&active_users=true`,
    { params: data }
  );
};

export const GET_SUBSCRIPTION_PRICE_STATUS_BY_STUDENT = (
  user_code: any
): Promise<any> => {
  return API.get(`${apiUrl}/subscription/prices?user_code=${user_code}`);
};

export const GET_SUBSCRIPTION_PRICE_STATUS_BY_SCHOOL_STUDENTS = (
  pageNumber: number,
  user_codes: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/user/subscriptions?users=${user_codes}&limit=${10000}&page=${pageNumber}&fields=name,user_code,institute_school_code`
  );
};

export const ADD_UPDATE_SUBSCRIPTION_PRICE = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/user/subscriptions`, data);
};

export const GET_STUDENT_SUBSCRIPTION_SEARCH_REPORTS_BY_SCHOOL_CODE = (
  schoolCode: any,
  data: any
): Promise<any> => {
  return API.get(
    `${apiUrl}/user/subscriptions?institute_school_code=${schoolCode}&limit=${10}&page=${0}&active_users=true&fields=user_code,name,email_id,standard,division,mobile_number`,
    { params: data }
  );
};

export const GET_STUDENT_SUBSCRIPTION_PRICE_LIST = (
  code: any,
  pageNumber: number
): Promise<any> => {
  return API.get(
    `${apiUrl}/app/configs?code=${code}&limit=${10}&page=${pageNumber}`
  );
};
export const ADD_UPDATE_SUBSCRIPTION_CONFIGS = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/app/configs`, data);
};
export const GET_SUBSCRIPTION_PRICE_BY_USER = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/subscription/prices`, { params: data });
};
