import React from 'react'
import { Route, Switch } from 'react-router'
import { IndividualStudentModulesList } from '../pages/dashboard/student/module/list'
import { ParentForm } from '../pages/dashboard/student/parent/form'
import { ParentList } from '../pages/dashboard/student/parent/list'
import { IndividualUserPaymentList } from '../pages/dashboard/student/payment/list'

const NestedIndividualStudent = () => {
    return (
        <Switch>
            <Route path="/dashboard/individual-student/info/:id/parentform/:userCode/:schoolName/:parentId" component={ParentForm}></Route>
            <Route path="/dashboard/individual-student/info/:id/parentlist/:userCode/:schoolName" component={ParentList}></Route>
            <Route path="/dashboard/individual-student/info/:id/modulelist/:userCode/:schoolName" component={IndividualStudentModulesList}></Route>
            <Route path="/dashboard/individual-student/info/:id/paymentlist/:userCode/:schoolName" component={IndividualUserPaymentList}></Route>

        </Switch>
    )
}

export default NestedIndividualStudent