import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { CREATE_ASSIGN_SCHOOL, GET_MODULES, GET_SCHOOL_FILTER, GET_TOTAL_MODULES } from '../../../app/service/module.service';
import FormBuilder from '../../../components/form-builder'
import { ToastContainer, toast } from 'react-toastify';
import { GET_ALL_SCHOOL_LIST, GET_TOTAL_SCHOOLS } from '../../../app/service/school.service';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { MultiSelect } from 'react-multi-select-component';

export const AssignSchool = () => {
    const [moduleData, setModuleData] = useState<any>([]);
    const [subModuleData, setSubModuleData] = useState<any>([]);
    const [moduleVersionData, setModuleVersionData] = useState<any>([]);
    const [targetData, setTargetData] = useState<any>([]);
    const [schoolData, setSchoolData] = useState<any>([]);
    const [schoolList, setSchoolList] = useState<any[]>([]);
    const [schoolSelected, setSchoolSelected] = useState<any>([]);
    const [assignSchoolData, setAssignSchoolData] = useState<any>({});
    const [assignSchoolValidationErrors, setAssignSchoolValidationErrors] = useState<FormControlError[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [cityData, setCityData] = useState<any>([]);
    const [amount, setAmount] = useState<any>(0);
    const [schoolErorr, setSchoolErorr] = useState<boolean>(false);
    const history = useHistory();
    const formValidations = [
        new FormField('module_code', [FormValidators.REQUIRED]),
        new FormField('sub_module', [FormValidators.REQUIRED]),
        new FormField('module_ver', [FormValidators.REQUIRED]),
        new FormField('target_std', [FormValidators.REQUIRED]),
        // new FormField('state', [FormValidators.REQUIRED]),
        // new FormField('district', [FormValidators.REQUIRED]),
        // new FormField('taluka', []),
        // new FormField('school_code', [FormValidators.REQUIRED]),
        new FormField('amount', []),
    ];

    useEffect(() => {
        GET_TOTAL_MODULES().then((res: any) => {
            if (res) {
                const data = res.rows;
                setModuleData(data);
            }
        });

        LOOK_UP_DATA().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                const data = res.states;
                setStatesData(data);
            }
        });
        getSchools();

    }, []);

    const handleInput = (data: any) => {
        setAssignSchoolData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setAssignSchoolValidationErrors(errors);
    }

    const handleModuleChange = (e: any) => {
        setSubModuleData([]);
        const preparedData = moduleData.filter((el: any) => el.module_code === e.target.value);
        setAmount(preparedData[0]?.amount ? preparedData[0]?.amount : 0);
        setSubModuleData(preparedData);
    }

    const handleSubModuleChange = (e: any) => {
        setModuleVersionData([]);
        const preparedData = moduleData.filter((el: any) => el.sub_module === e.target.value);
        setModuleVersionData(preparedData);
    }

    const handleModuleVersionChange = (e: any) => {
        setTargetData([]);
        const preparedData = moduleData.filter((el: any) => el.module_ver === e.target.value);
        setTargetData(preparedData);
    }

    const handleTargetChange = (e: any) => {
    }

    const handleSchoolChange = (e: any) => {

    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(assignSchoolValidationErrors, control);
        return value;
    }

    const handleStateChange = (e: any) => {
        const data = { ...assignSchoolData.value };
        data.state = e.target.value;

        setSchoolSelected([]);
        let preparedDataValue = {
            state: data.state,
        }
        if (e.target.value) {
            const preparedData = statesData.filter((el: any) => el.state === e.target.value);
            setDistrictData(preparedData[0].districts);
            setCityData(preparedData[0].cities);
            getSchools(preparedDataValue);
        } else {
            setDistrictData([]);
            getSchools();
        }
    }

    function handleAmountChange(e: any) {
        const data = { ...assignSchoolData.value };
        data.amount = e.target.value;
        setAmount(e.target.value);
    }

    const handleDistrictChange = (e: any) => {
        const data = { ...assignSchoolData.value };
        data.district = e.target.value;
        setSchoolSelected([]);
        let preparedData = {
            state: data.state,
            district: data.district,
        }
        if (e.target.value) {
            getSchools(preparedData);
        }
        else {
            let preparedStates = {
                state: data.state,
            }
            getSchools(preparedStates);
        }
    }

    function getSchools(data?: any) {
        if (data) {
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
        }
        let preData = data ? data : {};
        GET_ALL_SCHOOL_LIST(preData).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                const data = res?.rows;
                const preparedData = [];
                for (let i = 0; i < data.length; i++) {
                    preparedData.push({
                        label: data[i].name_of_school,
                        value: data[i].school_code
                    })
                }
                setSchoolList(preparedData);
            }
        });
        // if (data) {
        //     for (const key of Object.keys(data)) {
        //         if (data[key] === "") {
        //             delete data[key];
        //         }
        //     }
        //     GET_SCHOOL_FILTER(data).then((res: any) => {
        //         if (res.status === 'fail') {
        //             toast.error(res.message);
        //         } else {
        //             const data = res['rows'];
        //             setSchoolData(data);
        //             const preparedData = [];
        //             for (let i = 0; i < data.length; i++) {
        //                 preparedData.push({
        //                     label: data[i].name_of_school,
        //                     value: data[i].school_code
        //                 })
        //             }
        //             setSchoolList(preparedData);
        //         }
        //     });
        // } else {

        // }

    }

    const handleSubmit = () => {
        setIsFormSubmitted(true);
        const assignsSchoolData = { ...assignSchoolData.value };
        const errors: FormControlError[] = FormValidator(formValidations, assignsSchoolData);
        setAssignSchoolValidationErrors(errors);
        // assignsSchoolData.amount = amount;
        assignsSchoolData.module_uuid = subModuleData[0]?.uuid ? subModuleData[0]?.uuid : '';
        assignsSchoolData.amount = amount ? amount : 0;
        assignsSchoolData.school_code = "";
        delete assignsSchoolData['state'];
        delete assignsSchoolData['district'];
        // delete assignsSchoolData['taluka'];
        const schoolCodes = [];
        for (let i = 0; i < schoolSelected.length; i++) {
            schoolCodes.push(schoolSelected[i].value);
        }
        assignsSchoolData.school_codes = schoolCodes;
        delete assignsSchoolData[""];
        if (errors.length < 1 && schoolSelected.length > 0) {
            CREATE_ASSIGN_SCHOOL(assignsSchoolData).then(res => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    toast.success("Assign School Created Successfully");
                    history.push('/dashboard/module/list');
                }
            });
        } else {
            if (schoolSelected.length == 0) {
                setSchoolErorr(true);
            }
        }
    }
    const onSelectedSchool = (e: any) => {
        setSchoolErorr(false);
        if (e) {
            setSchoolSelected(e);
        } else {
            setSchoolErorr(true);
        }
    }

    return (
        <div className="row border-top border-primary py-3">
            {/* <h2> Assign School</h2> */}
            <h2 className='mb-4'> Assign Module to School</h2>
            <FormBuilder onUpdate={handleInput}>
                <form>
                    <div className="row custom-form">
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Module Name <span className="f18 text-danger ps-1">*</span></label>
                                <select name="module_code" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleChange(e) }}>
                                    <option value="">Select module</option>
                                    {moduleData.map((name: any, i: number) => (
                                        <option key={i} value={name.module_code}>
                                            {name.module_name}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('module_code') && <p className="text-danger">Please select module name</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Sub Module <span className="f18 text-danger ps-1">*</span></label>
                                <select name="sub_module" className="form-select form-select-lg mb-3" onChange={(e) => { handleSubModuleChange(e) }}>
                                    <option value="">Select sub module</option>
                                    {subModuleData.map((name: any, i: number) => (
                                        <option key={i} value={name.sub_module}>
                                            {name.sub_module}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('sub_module') && <p className="text-danger">Please select sub module</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0"> Module Version <span className="f18 text-danger ps-1">*</span></label>
                                <select name="module_ver" className="form-select form-select-lg mb-3" onChange={(e) => { handleModuleVersionChange(e) }}>
                                    <option value="">Select module version</option>
                                    {moduleVersionData.map((name: any, i: number) => (
                                        <option key={i} value={name.module_ver}>
                                            {name.module_ver}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('module_ver') && <p className="text-danger">Please select module version</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Target Standard <span className="f18 text-danger ps-1">*</span></label>
                                <select name="target_std" className="form-select form-select-lg mb-3" onChange={(e) => { handleTargetChange(e) }}>
                                    <option value="">Select target standard</option>
                                    {targetData.map((name: any, i: number) => (
                                        <option key={i} value={name.target_std}>
                                            {name.target_std}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('target_std') && <p className="text-danger">Please select target standard</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">State</label>
                                <select name="state" className="form-select form-select-lg mb-3" onChange={(e) => { handleStateChange(e) }}>
                                    <option value="">Select state</option>
                                    {statesData.map((name: any, i: number) => (
                                        <option key={i} value={name.state}>
                                            {name.state}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">District</label>
                                <select name="district" className="form-select form-select-lg mb-3" onChange={(e) => { handleDistrictChange(e) }}>
                                    <option value="">Select district</option>
                                    {districtData.map((name: any, i: number) => (
                                        <option key={i} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-2">Schools <span className="f18 text-danger ps-1">*</span></label>
                                <MultiSelect
                                    options={schoolList}
                                    value={schoolSelected}
                                    onChange={(e: any) => onSelectedSchool(e)}
                                    labelledBy="Select"
                                />
                                {schoolErorr && <p className="text-danger">Please select school</p>}
                                {/* <select name="school_code" className="form-select form-select-lg mb-3" onChange={(e) => { handleSchoolChange(e) }}>
                                    <option value="">Select school</option>
                                    {schoolData.map((name: any, i: number) => (
                                        <option key={i} value={name.school_code}>
                                            {name.name_of_school}
                                        </option>
                                    ))}
                                </select>
                                {isFormSubmitted && !getInputValid('school_code') && <p className="text-danger">Please select school</p>} */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="mb-4">
                                <label className="form-label mb-0">Amount</label>
                                <input className="form-control form-control-lg" type="text" name="amount" value={amount} onChange={(e) => { handleAmountChange(e) }} />
                            </div>
                        </div>
                    </div>
                </form>
            </FormBuilder>
            <div className="form-footer border-top border-primary py-3 text-end mt-4">
                <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={handleSubmit}>Add</a>
                <Link className="text-dark text-decoration-none ms-2 cursor-pointer" to="/dashboard/module/list">Cancel</Link>
            </div>
        </div>
    )
}


