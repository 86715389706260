import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { TeacherDashBoardRoutes } from "../../routes/teacher-dashboard/teacher-dashboard";
import MOLECULERFORMULA from "../../institute-dashboard-assets/images/molecular-formula.png";
import MATHEMATICS from "../../institute-dashboard-assets/images/mathematics (1).png";
import HEATINGFLASK from "../../institute-dashboard-assets/images/heating-flask.png";
import BOTANYEXPERIMENT from "../../institute-dashboard-assets/images/botany-experiment.png";
import MATHTEST from "../../institute-dashboard-assets/images/maths-test.png"
import MATHHOMEWORK from "../../institute-dashboard-assets/images/maths-homework.png"


function TeacherDashboard() {
    const [canShowPopup, setCanShowPopup] = useState(false);
    const history = useHistory();
    const userName = localStorage.getItem("user_name");
    const userRole = localStorage.getItem("user_role");
    const [toggleIcon, setToggleIcon] = useState<any>("nav_toggler");
    const [header, setHeader] = useState<any>("header");
    const [body, setBody] = useState<any>("inside-bg");
    const [nav, setNav] = useState<string>("l-navbar");
    const [icon, setIcon] = useState<string>("bx bx-menus");
    const schoolCode = localStorage.getItem("institute_code");
    const splitLocation: any = window.location.href.split("/");
    const tabData = splitLocation[6];
    const [logotTooltip, setLogoutTooltip] = useState<boolean>(false);
    const moduleLists: any[] = JSON.parse(localStorage.getItem("modules_list") || '[]');

    useEffect(() => {


    }, [splitLocation]);
    const navToggle = (): void => {
        toggleIcon === "nav_toggler" ? setToggleIcon("nav_toggler toggle") : setToggleIcon("nav_toggler");
        body === "inside-bg" ? setBody("body-pd") : setBody("inside-bg");
        header === "header" ? setHeader("header body-pd") : setHeader("header");
        nav === "l-navbar" ? setNav("l-navbar show") : setNav("l-navbar");
        icon === "bx bx-menus" ? setIcon("bx bx-x") : setIcon("bx bx-menus");

    }

    const handleclose = () => {
        setCanShowPopup(false);
    };

    const handleSubmit = () => {
        setCanShowPopup(false);
        history.push("/home");
        localStorage.clear();
    };

    function onClickHome() {
        setCanShowPopup(true);
    }
    const getNavLink = (moduleData: any) => {
        let moduleCode = moduleData?.module_code;
        switch (moduleCode) {
            case "ABHYAAS":
                return `/teacher-dashboard/view/abhyaas/teacher-initiatives`;
            case "PARIKSHA":
                return `/teacher-dashboard/view/pariksha/teacher-initiatives`;
            default:
                return "";
        }
    }
    const getIcons = (moduleCode: string) => {
        switch (moduleCode) {
            case "CAS":
                return "far fa-file-alt";
            case "MATHS":
                return "fa fa-book";
            case "ABHYAAS":
                return "bi bi-book";
            // return "fa fa-fw fa-leanpub";
            case "PARIKSHA":
                return "fa fa-fw fa-building-o";
            default:
                return "";
        }
    }

    return (
        <div>
            <body className={body}>
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Are You Sure Want To Logout
                                </h5>
                                <button
                                    type="button"
                                    className="close popup-close cursor-pointer"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={handleclose}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary cursor-pointer"
                                    onClick={handleSubmit}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary cursor-pointer"
                                    data-dismiss="modal"
                                    onClick={handleclose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-2">
                    <header className={header} id="header">
                        <div className="header_toggle" onClick={navToggle}> <i className={icon} id="header-toggle"></i> </div>
                        <span className="nav_logo mb-0 d-lg-none"><i className="nav_logo-icon"></i></span>
                        <div className="me-md-auto ms-md-3 d-none d-lg-block"> <span className="f18 fw-light">Teacher Dashboard</span> </div>
                        <div className="position-relative">
                            <div className="d-none d-lg-flex align-items-center ">
                                <span className="text-end user-name pe-3">
                                    {userName}
                                    <br />
                                    <small>{userRole}</small>
                                </span>
                                <a className="mx-3 logout-icon cursor-pointer" onMouseEnter={() => setLogoutTooltip(true)}
                                    onMouseLeave={() => setLogoutTooltip(false)}
                                    onClick={() => { onClickHome() }}>Sign out</a>
                            </div>
                            {logotTooltip && <p className="mb-0 position-absolute bg-white  border border-secondary px-1 f14 rounded-2 " style={{ right: "5px" }}>Logout</p>}

                        </div>
                    </header>
                    <div className={nav} id="nav-bar">
                        <nav className="navinstitute">
                            <div> <a className="nav_logo mb-3"> <i className="nav_logo-icon"></i> </a>
                                <div className={`nw_nav srl_bar  ${nav !== "l-navbar" && "show"} `}>
                                    <div className="nav_list">
                                        <NavLink to='/teacher-dashboard/view' className="nav_link"> <i className="dashboard-icon nav-icon"></i><span className="nav_name">Dashboard</span></NavLink>
                                        {moduleLists.length > 0 && moduleLists?.map((item: any, i: number) => (
                                            <>
                                                {item?.module_code !== "MATHS" ?
                                                    <NavLink to={`${getNavLink(item)}`} className="nav_link py-2 " key={i}> <i className={`${getIcons(item?.module_code)} ${item?.module_code === "PARIKSHA" ? "f14" : "sd_icons"}`}></i><span className="nav_name f14">{item?.module_code}</span></NavLink>
                                                    : <p className="nav_link py-2 mb-0 text-secondary"> <i className="  fa fa-book"></i><span className="nav_name">MATHS</span></p>}
                                            </>
                                        ))}
                                        <NavLink to='/teacher-dashboard/profile/view' className="nav_link  py-2"> <i className="profile-icon nav-icon"></i><span className="nav_name">Profile</span></NavLink>
                                    </div>
                                    <div className="d-lg-none nav_link ps-3 py-2" onClick={() => { onClickHome(); }}>
                                        <i className="bx bx-log-out nav-icon "></i>
                                        <span className="nav_name  ">SignOut</span>
                                    </div>
                                    <div className={`mt-4 moleculer ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons" src={MOLECULERFORMULA} alt="" />
                                    </div>
                                    <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons my-2" src={MATHEMATICS} alt="" />
                                    </div>
                                    <div className={`moleculer ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={HEATINGFLASK} alt="" />
                                    </div>
                                    <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={BOTANYEXPERIMENT} alt="" />
                                    </div>
                                    <div className={`moleculer ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={MATHTEST} alt="" />
                                    </div>
                                    <div className={`mathematics ${nav !== "l-navbar" && "show"}`}>
                                        <img className="side_bar_icons  my-2" src={MATHHOMEWORK} alt="" />
                                    </div>
                                </div>

                            </div>

                        </nav>
                    </div>
                    <div className="content-body main-title">
                        <div className="container-fluid">
                            <div className=" ">

                                <TeacherDashBoardRoutes />
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </div>
    );
}
export default TeacherDashboard;
