import React from 'react';
import SubscriptionRoutes from './routes';

const SubscriptionPage = (props: any) => {

    return (
        <div>
            <SubscriptionRoutes {...props} />
        </div>
    );
};

export default SubscriptionPage;