import React from "react";
import { Link, useHistory } from "react-router-dom";
const ParikshaTeacherInitiative = () => {
    const teacherInfo: any = JSON.parse(localStorage.getItem("teacher-info") || '{}');
    const history = useHistory();

    const onClickClassTeacher = () => {
        history.push(`/teacher-dashboard/view/pariksha/class-dashboard`)
    }
    const onClickSubjectTeacher = () => {
        history.push(`/teacher-dashboard/view/pariksha/subjects-list`)
    }
    return (
        <div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                    <li className="breadcrumb-item "><Link to="/teacher-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                    <li className="breadcrumb-item "><span>Pariksha</span></li>
                </ol>
            </nav>
            <h4 className="mb-0 f22 text-center mt-5 fw-bold">WELCOME TO PARIKSHA INITIATIVE !</h4>
            <h5 className="text-inprogess f18 text-center mt-4"> PLEASE ACCESS YOUR DASHBOARD AS A</h5>
            <div className="row justify-content-center mt-5">
                <div className="col-11 col-md-10 col-lg-7 ">
                    <div className="d-sm-flex justify-content-sm-between d-block">
                        <div className=" text-center">
                            <button className="class_teacher_btn" disabled={teacherInfo?.teacher_type === "SUBJECT_TEACHER"} onClick={onClickClassTeacher}>
                                Class Teacher
                            </button>
                        </div>
                        <div className="text-center mt-5 mt-sm-0">
                            <button className="subject_teacher_btn" onClick={onClickSubjectTeacher}>
                                Subject Teacher
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default ParikshaTeacherInitiative;