import { Switch, Route, Redirect } from "react-router";
import AddSchoolPage from "../../dashboard/school/add-school";
import { StudentsForm } from "../../dashboard/school/students/form";
import { ManageSchool } from "./manage-shcool";
import { SchoolInFormation } from "./schoolinfo";
import SchoolFormPage from "../../../../src/pages/dashboard/school/form";
import { InstituteProfileStudentPayments } from "./payment-list";


interface Props {
    match?: any;
    courseId: number;
}
const ManageSchoolRoutes = (props: Props) => {
    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }

    return (
        <Switch>
            <Route exact path={`${url}/list`} >
                <ManageSchool></ManageSchool>
            </Route>
            <Route exact path={`${url}/list/form/:id`} >
                <SchoolFormPage></SchoolFormPage>
            </Route>
            <Route path={`${url}/list/new`} exact>
                <AddSchoolPage></AddSchoolPage>
            </Route>
            <Route path={`${url}/list/new/:id`} exact>
                <AddSchoolPage></AddSchoolPage>
            </Route>
            <Route path={`${url}/list/info/:id`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/principalform/:schoolCode/:userId`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/viceprincipalform/:schoolCode/:userId`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/adminform/:schoolCode/:userId`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/studentsform/:schoolCode/:userId`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/teacherslist/:schoolCode`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/studentslist/:schoolCode`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/:id/studentsform/:schoolCode/:userId`} exact>
                <StudentsForm></StudentsForm>
            </Route>
            <Route path={`${url}/list/info/:id/student-count-list/:schoolCode`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/modulelist/:schoolCode`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/paymentlist/:schoolCode`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/info/:id/teachersform/:schoolCode/:userId`} exact>
                <SchoolInFormation></SchoolInFormation>
            </Route>
            <Route path={`${url}/list/:id/payment-list/:schoolCode/`} exact>
                <InstituteProfileStudentPayments></InstituteProfileStudentPayments>
            </Route>
        </Switch>
    );
}
export default ManageSchoolRoutes;