import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import NestedSubscription from "../../../routes/inside-subscription-routes";

const SubscriptionInfo: React.FC = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    return (
        <div>
            <div className="border-top border-primary py-3">
                <h2 className="mt-3 mb-5">Configurations</h2>
                <ul className="nav nav-tabs custom-tabs scrolling_nav ">
                    <li className="nav-item border-0">
                        <NavLink
                            className={
                                splitLocation[5] === "principalform"
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            to={{
                                pathname: `/dashboard/subscription/info/list`,
                                state: { data: "" },
                            }}
                        >
                            Subscription
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink
                            className={
                                splitLocation[5] === "principalform"
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            to={{
                                pathname: `/dashboard/subscription/info/reminder-days`,
                                state: { data: "" },
                            }}
                        >
                            Reminder Days
                        </NavLink>
                    </li>
                    <li className="nav-item ">
                        <NavLink
                            className={
                                splitLocation[5] === "principalform"
                                    ? "nav-link active"
                                    : "nav-link"
                            }
                            to={{
                                pathname: `/dashboard/subscription/info/dormant`,
                                state: { data: "" },
                            }}
                        >
                            Dormant
                        </NavLink>
                    </li>
                </ul>
                <div>
                    <NestedSubscription />
                </div>
            </div>
        </div>
    )
}

export default SubscriptionInfo;