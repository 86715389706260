import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
const AbhyaasTeacherSubjectStudentList = () => {
    const [studentList, setStudentsList] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const moduleData: any = JSON.parse(localStorage.getItem("teacher_moduleData") || '{}');
    const subjectInfo: any = JSON.parse(localStorage.getItem("selected_subject_info") || '{}');

    const history = useHistory();


    useEffect(() => {
        setLoading(true);
        // Retrieve the student list from localStorage when the component mounts
        const storedData = localStorage.getItem("subject_student_List");
        if (storedData) {
            try {
                const studentList = JSON.parse(storedData);
                setStudentsList(studentList);
                setLoading(false);
            } catch (error) {
                console.error("Failed to parse student list from localStorage", error);
                setLoading(false);
            }
        }
    }, []);

    const handleBack = () => {
        history.push(`/teacher-dashboard/view/abhyaas/subject-dashboard`);
    }
    const handleSubject = (studentInfo: any, subjectInfo: any) => {
        history.push(`/teacher-dashboard/view/abhyaas/summary/${moduleData?.module_uuid}/${studentInfo?.user_code}/${studentInfo?.institute_school_code}/${subjectInfo?.board_uuid}/${subjectInfo?.medium_uuid}/${subjectInfo?.standard_uuid}/${subjectInfo?.uuid}/${"subject"}`);
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/teacher-dashboard/view"><span className='pe-1'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to="/teacher-dashboard/view/abhyaas/teacher-initiatives">Abhyaas</Link></li> /
                        <li className="breadcrumb-item ">Dashboard of subject teacher</li>
                    </ol>
                </nav>
                <div className="text-end me-md-5 me-3">
                    <button className='btn btn-primary px-4 f14 py-1' onClick={handleBack}>Back</button>
                </div>
                <h4 className="mb-0 f18 text-center mt-1">Class : {subjectInfo?.standard}th | Division : {subjectInfo?.division} | Subject : {subjectInfo?.subject?.toUpperCase()}</h4>
                <h6 className="text-inprogess f18 text-center mt-5">Student List</h6>
                <div className="row justify-content-center mt-3">
                    <div className="col-12 col-md-11 col-lg-10">
                        <div className=" table-responsive">
                            <table className="teacher_student_report_table">
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Student Name</th>
                                        <th className="text-center">Class</th>
                                        <th className="text-center">Division</th>
                                        <th> Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentList?.map((data: any, i: number) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{data?.name}</td>
                                            <td className="text-center">{data?.standard}th</td>
                                            <td className="text-center">{data?.division}</td>
                                            <td className="d-inline-block border-end-0 border-bottom-0 py-1 border-top-0">
                                                {data?.subjects?.map((item: any, j: number) => (
                                                    <button className={`btn btn-primary py-1 px-3 f12 border-0 mx-1`} style={{ backgroundColor: '#00B050' }} key={j} onClick={() => handleSubject(data, item)}>Abhyaas Summary</button>
                                                ))}
                                            </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>}
        </div>

    )
}
export default AbhyaasTeacherSubjectStudentList;