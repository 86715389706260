import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GET_ABYAAS_STUDENTS_GRAPH_DATA, GET_STUDENTS_BY_CLASS_TEACHERS } from "../../../../app/service/teacher-dashboard-abhyaas";
import { toast } from "react-toastify";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const AbhyaasTeacherSubjectReport = () => {
    const subjectInfo: any = JSON.parse(localStorage.getItem("selected_subject_info") || '{}');
    const teacherInfo: any = JSON.parse(localStorage.getItem("teacher-info") || '{}');
    const moduleData: any = JSON.parse(localStorage.getItem("teacher_moduleData") || '{}');
    const [studentsInfo, setSudentsInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [abhyaasDataValue, setAbhyaasDataValue] = useState<any>([0]);
    const [parkshaDataValue, setParkshaDataValue] = useState<any>([0]);
    const [subjectsList, setSubjectList] = useState<any>([]);
    const history = useHistory();
    const options = {
        chart: {
            type: 'column',
            backgroundColor: "transparent",
            height: 350,
            margin: [50, 50, 100, 50]
        },
        title: {
            text: "Worksheets completed in the week",
            align: 'center',
            style: {
                color: 'black',
                fontSize: '16px',
                fontWeight: 'normal'
            },
        },
        xAxis: {
            lineWidth: 0,
            categories: subjectsList,
            type: 'category',
            style: {
                color: 'black',
                fontSize: '10px',
                fontWeight: 'normal'
            },
        },
        yAxis: {
            title: {
                text: 'Number of worksheets',
                style: {
                    fontSize: '14px',
                    width: 200,
                    fontWeight: 'normal',
                    color: 'black',
                }
            },
            tickPixelInterval: 50,
            gridLineColor: "#D9D9D9",
            gridLineWidth: 1,
        },
        legend: {
            symbolWidth: 8,
            symbolHeight: 8,
            symbolRadius: 0,
            symbolPadding: 5,
            y: -1,
            itemStyle: {
                cursor: 'default', // Change cursor to default to indicate non-interactivity
            },
        },
        plotOptions: {
            column: {
                size: '41%',
                pointPadding: 0.2,
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    style: {
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'normal'
                    },
                },
                events: {
                    legendItemClick: function () {
                        return false;
                    }
                }
            },
            allowPointSelect: false,
        },

        series: [
            {
                name: 'Worksheet by Assement',
                data: abhyaasDataValue,
                color: '#156082'

            },
            {
                name: 'Worksheets by Pariksha',
                data: parkshaDataValue,
                color: '#E97132'
            }
        ],
        credits: {
            enabled: false
        }
    };
    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        setLoading(true);
        try {
            const studentDetails = await getStudents();
            await getGraphData(studentDetails);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }

    };
    const getStudents = async () => {
        setLoading(true);
        try {
            let Payload = {
                teacher_type: "SUBJECT_TEACHER",
                user_code: teacherInfo?.user_code,
                module_uuid: moduleData?.module_uuid,
                subject: subjectInfo?.subject,
                standard: subjectInfo?.standard,
                division: subjectInfo?.division
            }
            const response: any = await GET_STUDENTS_BY_CLASS_TEACHERS(Payload);
            if (response.status === 'fail') {
                toast.error(response?.err);
            } else {
                setSudentsInfo(response);
                let subjectsArray = response?.total_students[0]?.subjects?.map((ele: any) => (
                    ele?.name
                ));
                setSubjectList(subjectsArray);
                return response;

            }
        } catch (error) {

        }
    }
    const getGraphData = async (studentData: any) => {
        const modulesList: any[] = JSON.parse(localStorage.getItem("modules_list") || '[]');

        const filteredModules = modulesList.filter(
            (ele: any) => ele.module_code === "ABHYAAS" || ele.module_code === "PARIKSHA"
        );

        const modulesListArray = filteredModules.map((module: any) => ({
            module_uuid: module.module_uuid,
            subjects: studentData?.total_students[0]?.subjects.map((subject: any) => subject.uuid)
        }));
        try {
            let Payload = {
                standard: teacherInfo?.class_section_details?.standard,
                section: teacherInfo?.class_section_details?.section,
                school_code: teacherInfo?.institute_school_code,
                modules: modulesListArray
            }
            const response: any = await GET_ABYAAS_STUDENTS_GRAPH_DATA(Payload);
            if (response.status === 'fail') {
                toast.error(response?.err);
            } else {
                if (response?.results?.ABHYAAS) {
                    setAbhyaasDataValue(Object.values(response?.results?.ABHYAAS));
                }
                if (response?.results?.PARIKSHA) {
                    setParkshaDataValue(Object.values(response?.results?.PARIKSHA));
                }

            }
        } catch (error) {

        }
    }
    const handleBack = () => {
        history.push(`/teacher-dashboard/view/abhyaas/subjects-list`)
    }
    const onClickNoOfStudents = () => {
        if (studentsInfo?.total_students_count > 0) {
            localStorage.setItem("subject_student_List", JSON.stringify(studentsInfo?.total_students));
            history.push(`/teacher-dashboard/view/abhyaas/subject-students`);
        }

    }
    const onClickActiveStudents = () => {
        if (studentsInfo?.active_students_count > 0) {
            localStorage.setItem("subject_student_List", JSON.stringify(studentsInfo?.active_students));
            history.push(`/teacher-dashboard/view/abhyaas/subject-students`);
        }
    }
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/teacher-dashboard/view"><span className='pe-1'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to="/teacher-dashboard/view/abhyaas/teacher-initiatives">Abhyaas</Link></li> /
                        <li className="breadcrumb-item ">Dashboard of Subject Teacher</li>
                    </ol>
                </nav>
                <div className="text-end me-md-5 me-3">
                    <button className='btn btn-primary px-4 f14 py-1' onClick={handleBack}>Back</button>
                </div>
                <h4 className="mb-0 f18 text-center mt-1">Class : {subjectInfo?.standard}th | Division : {subjectInfo?.division} | Subject : {subjectInfo?.subject?.toUpperCase()}</h4>
                <div className="class_report_cards">
                    <div className="class_card_view cardOne cursor-pointer" onClick={onClickNoOfStudents}>
                        <h3>{studentsInfo?.total_students_count ? studentsInfo?.total_students_count : "0"}</h3>
                        <p className="mb-1">No Of Students</p>
                    </div>
                    <div className="class_card_view cardTwo cursor-pointer" onClick={onClickActiveStudents}>
                        <h3>{studentsInfo?.active_students_count ? studentsInfo?.active_students_count : "0"}</h3>
                        <p className="mb-1">No Of Active Students</p>
                    </div>
                    <div className="class_card_view cardThree">
                        <h3>10</h3>
                        <p className="mb-1">No Of Worksheet Allocated</p>
                    </div>
                </div>
                <div className="row justify-content-center mt-4 mb-3 mx-2 mx-lg-0">
                    <div className="col-12  col-lg-9 ab_graph_chart p-2" >
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
export default AbhyaasTeacherSubjectReport;