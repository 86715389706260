import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from '../../../../app/service/subscription.service';
import { useHistory } from 'react-router-dom';


interface PaymentData {
    amount: number;
    orderId: string;
    email: string;
    // Add other payment-related fields here
}

export const PaymentList = () => {
    const [paymentList, setPaymentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(false);
    const [paymentStudentList, setPaymentStudentList] = useState(false);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    const history = useHistory();


    useEffect(() => {
        getPayments();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleTable(JSON.parse(accessObjectsData));
    }, []);



    const getPayments = async () => {
        setLoading(true);
        try {
            const res: any = await GET_STUDENT_SUBSCRIPTION_PRICE_LIST("", 0);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res?.rows?.filter((data: any) => data?.code === "ANNUAL_MEMBERSHIP_AMOUNT");
                    data.forEach((element: any) => {
                        element.name = capitalizeWords(element?.name);
                        element.membership_amount = element?.data?.membership_amount;
                        element.price_per_day = (element?.data?.membership_amount / 365);
                        element.notes = element?.data?.notes;
                        element.discount_percentage = element?.data?.discount_percentage;
                        element.final_price = element?.data?.membership_amount * (100 - parseInt(element?.data?.discount_percentage)) / 100;
                        element.discount_price_per_day = (element?.final_price / 365);
                    });
                    setPaymentList(data);
                }
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
        }
    }



    function onClickPay(data?: any) {
        history.push(`/dashboard/school/${id}/${schoolsCode}/payment/list`);

        // history.push(`/dashboard/school/${id}/${schoolsCode}/${data.module_uuid}/payment/list`);
    }



    function onHandleTable(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter(
            (el: any) => el.object_code === "SCHOOLS"
        );
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (
                    preparedData[0].child[i].object_code === "SCHOOLS-PAYMENT" &&
                    preparedData[0].child[i].p_read === 1
                ) {
                    setPayment(true);
                }
                if (
                    preparedData[0].child[i].object_code === "SCHOOLS-PAYMENT-SELECT-STUDENT-LIST" &&
                    preparedData[0].child[i].p_read === 1
                ) {
                    setPaymentStudentList(true);
                }
            }
        }
    }
    const capitalizeWords = (name: string): string => {
        return name
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');
    };
    return (
        <div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className="mar-t-20 custom-scroll table-responsive">
                {payment && <table className="table fixed-table-header mb-0">
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            <th >Subscription</th>
                            <th>Price / Student</th>
                            <th>
                                Action
                            </th>
                        </tr>
                        {/* <tr>
                            <th>Sr.No</th>
                            <th>Module Name</th>
                            <th>Price / Student</th>
                            {paymentStudentList && <th>Action</th>}
                        </tr> */}
                    </thead>
                    <tbody>
                        {/* <tr>
                            <td>1</td>
                            <td colSpan={1}>Annual Memebrship </td>
                            <td>
                                <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" >Select Student List</button>
                            </td>
                        </tr> */}
                        {paymentList.map((data: any, i: number) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{data.name}</td>
                                <td>{data.membership_amount}</td>
                                {paymentStudentList && <td>
                                    <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay(data)}>Select Student List</button>
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>}
            {paymentList.length === 0 && <div style={{ textAlign: 'center' }}>
                No records Found
            </div>}
        </div>
    )
}
