import React, { useEffect, useRef, useState } from "react";
import { SubscriptionDormantGridCols } from "./data-grid-cols";
import { ADD_UPDATE_SUBSCRIPTION_CONFIGS, GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from "../../../../app/service/subscription.service";
import { toast } from "react-toastify";
import { DataTable } from "../../../../components/data-table";
import FormBuilder from "../../../../components/form-builder";

const SubscriptionDormant: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reminderList, setReminderList] = useState<any>([]);
    const [showViewDetails, setShowViewDetails] = useState<boolean>(false);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [currentData, setCurrentData] = useState<any>({});
    const subscriptionForm = useRef<any>({});
    const [days, setDays] = useState<string>("")

    useEffect(() => {
        FetchData();
    }, []);


    const FetchData = async () => {
        setLoading(true);
        try {
            const res: any = await GET_STUDENT_SUBSCRIPTION_PRICE_LIST("", 0);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.err);
                    setLoading(false);
                } else {
                    const data = res?.rows?.filter((data: any) => data?.code === "MEMBERSHIP_GRACE_PERIOD_DAYS");
                    if (data.length == 0 || data.length > 0) {
                        data.forEach((element: any) => {
                            element.name = capitalizeWords(element?.name);
                            element.days = element?.data?.grace_period_days;
                        });
                        setReminderList(data);
                    }


                }
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);

        }
    }
    const handleInput = (data: any) => {
        data.value = { ...data.value };
    }
    const handleUpdate = async () => {
        const data = { ...currentData }
        if (days) {
            try {
                const payLoad = {
                    name: data?.name,
                    code: data?.code,
                    data: {
                        grace_period_days: parseInt(days)
                    }
                }
                const res: any = await ADD_UPDATE_SUBSCRIPTION_CONFIGS(payLoad);
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    toast.success(res?.message);
                    handleclose();
                }
            } catch (error) {
            }
        }

    }
    const handlePricePerDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, ''); // Ensure only numbers are entered
        if (value) {
            setDays(value);
        } else {
            setDays("");
        }

    };
    const onEditPrice = (data: any) => {
        setCurrentData(data);
        setDays(data?.days);
        setCanShowPopup(true);

    }
    const viewDetails = (data: any) => {
        setCurrentData(data);
        setDays(data?.days);
        setShowViewDetails(true);
        setCanShowPopup(true);

    }
    const onPageChange = (data: any) => {


    }
    const handleclose = () => {
        setShowViewDetails(false);
        setCanShowPopup(false);
        setDays("");
        subscriptionForm.current.reset();
        FetchData();

    }
    const capitalizeWords = (name: string): string => {
        return name
            .split('_') // Split the string by underscores
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');
    };
    return (
        <div>
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title f18" id="exampleModalLabel">DORMANT</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <FormBuilder onUpdate={handleInput}>
                                <form ref={subscriptionForm}>
                                    <div className="row ">
                                        <div className="col-md-12 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">Name </label>
                                                <input className="form-control form-control-lg f18" type="text" name="name" disabled={true} defaultValue={currentData?.name} style={{ backgroundColor: 'hsl(0,0%,96.5%)' }} />
                                            </div>
                                        </div>
                                        <div className="col-md-12 custom-form">
                                            <div className="mb-4">
                                                <label className="form-label mb-0">	Expiration days <span className="f16 text-danger ps-1">*</span></label>
                                                <input className="form-control form-control-lg" type="text" name="days" value={days} onChange={handlePricePerDayChange} disabled={showViewDetails} style={{ backgroundColor: showViewDetails ? 'hsl(0,0%,96.5%)' : "" }} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </FormBuilder>
                        </div>

                        <div className="modal-footer">
                            {!showViewDetails && <button type="button" className="btn btn-primary cursor-pointer" onClick={handleUpdate}>Update</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>{showViewDetails ? "Close" : "Cancel"}</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={SubscriptionDormantGridCols} tableData={reminderList}
                        editInfo={onEditPrice}
                        onChange={viewDetails}
                        pageNumber={onPageChange} activePageNumber={0} pageNumbers={[1]}></DataTable>
                </div>}
        </div>
    )
}

export default SubscriptionDormant;