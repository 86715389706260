import { DataTableCol } from "../../../../components/data-table/types";

export const SubscriptionReminderDaysGridCols: DataTableCol[] = [
    {
        title: "Name",
        control: "name",
        canShowColumn: true,
    },
    {
        title: "Days",
        control: "day",
        canShowColumn: true,
    },
    {
        title: "Actions",
        control: "both",
        isEdit: true,
        isView: true,
        canShowColumn: true,
    },
];
