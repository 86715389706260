import React from 'react'
import { Route, Switch } from 'react-router'
import SubScription from '../pages/dashboard/subscription/list'
import SubscriptionDormant from '../pages/dashboard/subscription/dormant/list'
import SubscriptionReminderDays from '../pages/dashboard/subscription/reminder-days/list'


const NestedSubscription = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/subscription/info/list" component={SubScription}></Route>
            <Route path="/dashboard/subscription/info/dormant" component={SubscriptionDormant}></Route>
            <Route exact path="/dashboard/subscription/info/reminder-days" component={SubscriptionReminderDays}></Route>
        </Switch>
    )
}

export default NestedSubscription;