import React, { useEffect, useState } from "react";
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_BY_USER } from "../../app/service/subscription.service";
import { toast } from "react-toastify";
import { GET_USER_USER_CODE } from "../../app/service/users.service";
import moment from "moment";
import { CREATE_PAYMENT } from "../../app/service/payment-service";
import { CREATE_CCAVENUE_PAYMENT } from "../../app/service/ccavenue.service";
import { APPLY_COUPON_CODE, UPDATE_APPLIED_COUPON_CODE } from "../../app/service/promotions";
import { GET_SUBSCRIPTION_BY_USER_CODES } from "../../app/service/user-subscription";

interface PaymetProps {
    UserCode: any;
    redirectUrl: any;
    cancelUrl: any;
}

const UserPaymentsSubscription: React.FC<PaymetProps> = (props: PaymetProps) => {
    const [loading, setLoading] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [subscription, setSubscription] = useState<any>({});
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [couponData, setCouponData] = useState<any>({});
    const [couponCodeValue, setCouponCodeValue] = useState<any>("");
    const [couponLoading, setCouponLoading] = useState(false);
    const [applyDiscount, setApplyDiscount] = useState<boolean>(false);
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [showModules, setShowModules] = useState<boolean>(true);

    useEffect(() => {

        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const couponsData = JSON.parse(localStorage.getItem("cc_coupons_data") || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, couponsData);
                } else {
                    removeItemsFromeLocalStorage();
                    fetchAllData();
                }
            } else {
                fetchAllData();
            }
        } else {
            fetchAllData();
        }

    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            const userDetails = await getUserDetails();
            await Promise.all([
                getPaymentDetailsOfUser(userDetails),

            ]);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };
    const getUserDetails = async () => {
        try {
            const response: any = await GET_USER_USER_CODE(props?.UserCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response.rows[0];
                setStudentInfo(data);
                return data;
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            throw error;
        }
    };
    function getPaymentDetailsOfUser(studentInfo: any, discountApplied?: any) {
        const payLoad = {
            user_code: studentInfo?.user_code,
            discount_applied: discountApplied ? discountApplied : false
        }
        setPaymentDetailsLoading(true);
        GET_SUBSCRIPTION_PRICE_BY_USER(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
                setPaymentDetailsLoading(false);
            } else {
                if (res) {
                    let data = res;
                    setPaymentInfo(data);
                    // localStorage.setItem("cc_user_payment_info", JSON.stringify(data));
                    if (discountApplied) {
                        setApplyDiscount(true);
                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });

    }

    const handlePayment = () => {
        localStorage.setItem("cc_student_info", JSON.stringify(studentInfo));
        createPayment(paymentInfo);

    }
    function createPayment(paymentInfo: any) {
        const payload = {
            school_code: studentInfo?.institute_school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: couponData?.final_amount ? Math.round(couponData?.final_amount) : paymentInfo?.subscription_price
        }
        // if (parseInt(paymentInfo?.subscription_price) > 0) {
        CREATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (couponData?.final_amount == 0 && Object.keys(couponData?.rule_applied).length > 0 || (payload?.sub_amount == 0)) {
                        updateSubscription(res.txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo, "success", studentInfo, couponData);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            }
        });
        // } else {
        //     getAssignModule(paymentInfo, moduleInfo, studentInfo);
        // }
    }

    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }


    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, couponData?: any) {
        // if (paymentInfo?.subscription_payment_status === 'pending') {
        const payload = {
            data: [
                {
                    "uuid": "",
                    "school_code": studentInfo?.institute_school_code,
                    "user_code": studentInfo?.user_code,
                    "transaction_id": transactionId,
                    "amount": (couponData?.final_amount == 0 || couponData?.final_amount) ? couponData?.final_amount : parseInt(paymentInfo?.subscription_price),
                    // "transaction_details": JSON.stringify(transactionData),
                    "transaction_details": "",
                    "payment_status": "completed"
                }
            ]
        }
        if (paymentStatus === "success") {
            ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.message);
                } else {
                    getSubscriptionDetails(props?.UserCode);
                    // getAssignModule(paymentInfo, moduleInfo, studentInfo);
                    toast.success(res?.message);
                    if (Object.keys(couponData?.rule_applied).length > 0) {
                        updateAppliedCoupon(transactionId, couponData);
                    }
                    removeItemsFromeLocalStorage();
                    // props?.setCanShowPopup(false);
                    // props?.fetchAllData();
                    fetchAllData();
                }
            });
        } else {
            removeItemsFromeLocalStorage();
            fetchAllData();
        }
    }

    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: props?.redirectUrl,
            cancel_url: props?.cancelUrl,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function onApplyCouponValue(e: any) {
        if (e) {
            setCouponCodeValue(e.target.value);
        } else {
            setCouponData({});
            setCouponCodeValue("");
        }

    }
    function applyCoupon() {
        const payload = {
            promo_code: couponCodeValue?.replace(/\s/g, ''),
            students_count: 1,
            payable_amount: paymentInfo?.subscription_price,
            institute_code: studentInfo?.organization_code,
            school_code: studentInfo?.institute_school_code
        }
        if (payload?.promo_code) {
            setCouponData({});
            setCouponLoading(true);
            APPLY_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setCouponLoading(false);
                } else {
                    let data = res;
                    if (Object.keys(data?.rule_applied).length > 0) {
                        localStorage.setItem("cc_coupons_data", JSON.stringify(data));
                        data.coupon_code = payload?.promo_code;
                        setCouponData(data);
                    }
                    setCouponLoading(false);
                }
            });
        }

    }
    function updateAppliedCoupon(txnId: any, couponData?: any) {
        const payload = {
            promotion_uuid: couponData?.promotion_uuid ? couponData?.promotion_uuid : "",
            promo_code_uuid: couponData?.promocode_uuid ? couponData?.promocode_uuid : "",
            applied_for_user_code: props?.UserCode,
            applied_sponsor_uuid: "",
            applied_dt: todayDate,
            applied_rules: couponData,
            status: 2
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_APPLIED_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
        }
    }
    const getSubscriptionDetails = async (userCode: any) => {
        try {
            const response: any = await GET_SUBSCRIPTION_BY_USER_CODES(userCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response.data[0];
                if (new Date(data?.subscription_to) < new Date()) {
                    setIsExpired(true);
                }
                setSubscription(data);
            }
        } catch (error) {
            console.error('Error fetching subscription details:', error);
            throw error;
        }
    };

    const handleApplyDiscount = () => {
        getPaymentDetailsOfUser(studentInfo, true);
    }
    const removeCouponData = (removeType?: string) => {
        setApplyDiscount(false);
        setCouponData({});
        setCouponCodeValue("");
        localStorage.removeItem("cc_coupons_data");
        if (removeType === "discount") {
            getPaymentDetailsOfUser(studentInfo);

        }
    }
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcceptTerms(event.target.checked);
        // props?.setAcceptTerms(event.target.checked);
    };

    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_coupons_data'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const capitalizeWords = (name: string): string => {
        return name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };
    const getDiscountStatus = () => {
        if (paymentInfo?.discount_applied === "true" || (couponData?.final_amount == 0 || couponData?.final_amount)) {
            return true
        } else {
            return false
        }
    }
    return (
        <div className="row justify-content-center">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="col-12 col-md-9 col-lg-7 border border-2 pb-3 px-0 rounded-3">
                    <div className="std_payment_bx p-3 rounded-3">
                        <h4 className="mb-0 f18">{studentInfo?.name && capitalizeWords(studentInfo?.name)}</h4>
                        <h5 className="mb-1 f16 border-bottom border-3 pb-1">Class - {studentInfo?.standard}</h5>
                        <div className="px-2  py_bx border-2 ">
                            <div className="mt-3 mx-5  ">
                                <div className={`d-flex justify-content-between ${showModules && " py_bx border-2 "} mb-1`}>
                                    <h5 className="f18 position-relative"><span className={`expand_icon cursor-pointer  ${showModules ? "" : "px-1 f16"}`} onClick={() => setShowModules(!showModules)}>{!showModules ? "+" : <span>&#x2015;</span>}</span>Membership</h5>
                                    <h5 className="f18"> {getDiscountStatus() && <del className="me-3">&#x20b9;{paymentInfo?.actual_subscription_price}</del>} <span className={`${getDiscountStatus() && "py_grn_cls"}`}>&#x20b9;{(couponData?.final_amount == 0 || couponData?.final_amount) ? couponData?.final_amount : paymentInfo?.subscription_price}</span></h5>
                                </div>
                                {showModules && paymentInfo?.modules?.map((data: any, i: number) => (
                                    <div className="d-flex justify-content-between " key={i}>
                                        <h5 className="f16 fw-normal">
                                            <input className="me-2 mt-1 cursor-pointer module_checkbox" id="checked_module" type="checkbox" checked />{data?.module_code}</h5>
                                        <h5 className="f16 fw-normal py_grn_cls">Includes</h5>
                                    </div>
                                ))}
                            </div>

                        </div>
                        {parseInt(paymentInfo?.discount) > 0 && <div className="d-flex justify-content-center">
                            <div className="d-flex mt-3 me-2">
                                {applyDiscount && <h5 className="f16 apl_text_cl d-inline-block mt-1">{paymentInfo?.discount}%  discount applied successfully!! </h5>}
                                {!applyDiscount && <h5 className="f16 apl_text_cl d-inline-block mt-1">You can apply for an Instant discount of {paymentInfo?.discount}%</h5>}
                                <div>
                                    <button className="btn btn-success px-4 py-2 ms-3 f14 fw-bold" onClick={handleApplyDiscount} disabled={Object.keys(couponData).length > 0 || applyDiscount}>Apply</button>
                                    {applyDiscount && <button type="button" className="btn btn-success cursor-pointer   px-3 py-2 ms-2" onClick={() => removeCouponData("discount")}>Remove</button>}
                                </div>
                                {/* <div>
                                </div> */}
                            </div>
                        </div>}
                        {parseInt(paymentInfo?.discount) > 0 && <p className="f16 text-center mb-0">OR</p>}
                        {parseInt(paymentInfo?.actual_subscription_price) > 0 &&
                            <div className={`row justify-content-center mt-4`} >
                                <div className={`col-lg-${Object.keys(couponData).length > 0 ? "7" : "7"} col-md-10 col-11 d-flex`}>
                                    <input className='form-control form-control-lg f14 ' placeholder=' Coupon Code' type='text' value={couponCodeValue} onChange={(e) => onApplyCouponValue(e)} disabled={applyDiscount}></input>
                                    {couponLoading &&
                                        <div className="text-center mt-4 ms-1">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only ">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                    {!couponLoading && <button className="btn appl_cpn cursor-pointer pt-1 ms-2 px-4 py-1 f14 fw-bold text-white" onClick={applyCoupon} disabled={applyDiscount}>
                                        Apply</button>}
                                    {Object.keys(couponData).length > 0 && <button type="button" className="btn btn-success cursor-pointer  px-3 py-1 ms-2" onClick={() => removeCouponData("coupon")}>Remove</button>}
                                </div>
                            </div>
                        }
                        <div>
                            {paymentInfo?.notes?.length > 0 && <>
                                <h5 className="mb-1 f16 mt-1">Note</h5>
                                <ol className="ps-3">
                                    {paymentInfo?.notes?.map((item: string, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ol>
                            </>}
                            <div className="d-flex mt-2">
                                <input className="me-2 mt-1 cursor-pointer" type="checkbox" checked={acceptTerms} style={{ height: "16px", width: "16px" }} onChange={handleCheckboxChange} ></input> <p className="mb-1 f16 text-primary">Accept Terms & Conditions</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        {(Object.keys(paymentInfo).length > 0) && <button type="button" className="btn btn-primary cursor-pointer px-4 py-2 f18" onClick={handlePayment} disabled={!acceptTerms}>{((parseInt(paymentInfo?.subscription_price)) > 0 && couponData?.final_amount !== 0) ? 'Pay Now' : 'Assign'}</button>}
                        {/* <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Cancel</button> */}
                    </div>
                </div>}
        </div>
    )
}
export default UserPaymentsSubscription;