import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { BULK_UPLOAD_TEACHERS, DELETE_USER, GET_USER_BY_SCHOOL_CODE } from '../../../../app/service/users.service'
import { DataTable } from '../../../../components/data-table'
import { TeachersGridColsData } from './data-grid-cols'
import { ToastContainer, toast } from 'react-toastify';
import appConfig from '../../../../config/constant'

function TeacherlistData() {
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = parseInt(id);
    const schoolsCode = schoolCode;
    const [teachersList, setTeachersList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const userRole = localStorage.getItem("user_role");
    const [activePage, setActivePage] = useState<any>();
    const search = useLocation().search;
    const currentPageNumber: any = new URLSearchParams(search).get("page");
    const [pageArray, setPageNumbers] = useState<any>([]);
    const history = useHistory();

    useEffect(() => {
        getTeachers();
        getActionsInfo(userRole);


    }, []);


    function getActionsInfo(userRole: any) {
        for (let i = 0; i < TeachersGridColsData.length; i++) {
            if (TeachersGridColsData[i].title === "Actions") {
                if (userRole === 'INSTITUTE_ADMIN') {
                    TeachersGridColsData[i].isEdit = true;
                    TeachersGridColsData[i].isDelete = true;
                    TeachersGridColsData[i].setPassword = true;
                    TeachersGridColsData[i].canShowColumn = true;
                }
            }
        }
    }


    function getTeachers() {
        if (schoolsCode) {
            setLoading(true);
            GET_USER_BY_SCHOOL_CODE(schoolsCode, 'TEACHER', 0, {}).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res?.rows;
                    setTeachersList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setActivePage(data);
                    setActivePage(currentPageNumber ? currentPageNumber : 1);
                    setLoading(false);
                }
            });
        }
    }
    function onClickPreviousPage() {
        setActivePage(activePage - 1);
        onPageChange(activePage - 1);

    }
    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_USER_BY_SCHOOL_CODE(schoolsCode, 'TEACHER', pageNumber, {}).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                const data = res.rows;
                setTeachersList(data);
                const pageNumbers = Math.ceil(res.count / 10);
                const pageArray: any = [];
                for (let i = 1; i <= pageNumbers; i++) {
                    pageArray.push(i);
                }
                setPageNumbers(pageArray);
                setLoading(false);
            }
        });

    }

    const onEditTeacher = (data: any) => {
        history.push(`/institute-dashboard/manage-school/list/info/${schoolId}/teachersform/${schoolsCode}/${data.id}`);
    }
    const onDeleteTeacher = (data: any) => {
        setLoading(true);
        const id = data.user_code;
        DELETE_USER(id).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success("Teachers Deleted Successfully");
                getTeachers();
            }
        });
    }
    function handleUploadBulkUpload(e: any) {
        if (e.target.files && e.target.files[0]) {
            let formData = new FormData();
            formData.append('file', e.target.files[0], e.target.files[0].name);
            formData.append('school_code', schoolsCode);
            setLoading(true);

            BULK_UPLOAD_TEACHERS(formData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.error);
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.success(res?.message);
                    getTeachers();
                }
            });
        }

    }
    function handleDownloadTemplate() {
        setLoading(true);
        const data = '/templates/teachers_bulk_upload.xlsx';
        if (data) {
            const url = `${appConfig.FILE_UPLOAD_URL}${data.charAt(0) === "/" ? data.substring(1) : data}`;
            window.location.href = url;
            toast.success("Download Teacher Template Successfully");

        }
        setLoading(false);


    }
    return (
        <div>
            {(userRole === 'INSTITUTE_ADMIN') &&
                <div className="border-primary py-3 text-end">
                    <button className="btn btn-sm btn-primary px-4 rounded-12 cursor-pointer" style={{ marginRight: '10px', color: 'white' }} onClick={() => handleDownloadTemplate()}>Teacher List Template Download
                    </button>
                    <div className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block" style={{ marginRight: '10px', cursor: 'pointer' }}>Teacher list upload
                        <input type="file" style={{ cursor: 'pointer' }} onChange={(e) => handleUploadBulkUpload(e)} />
                    </div>
                    <Link to={`/institute-dashboard/manage-school/list/info/${schoolId}/teachersform/${schoolsCode}/0`} className="btn btn-primary btn-sm px-5 rounded-12">New</Link>
                </div>
            }
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <DataTable TableCols={TeachersGridColsData}
                editInfo={onEditTeacher} deleteInfo={onDeleteTeacher} type={"classic"}
                tableData={teachersList} activePageNumber={activePage}
                pageNumber={onPageChange} pageNumbers={pageArray}></DataTable>}
        </div>
    )
}
export default TeacherlistData;