import API from "../utility/axios";
const apiUrl = "/api/v1";
export const GET_STUDENTS_BY_CLASS_TEACHERS = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/class/students`, { params: data });
};
export const GET_ABYAAS_STUDENTS_GRAPH_DATA = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/abhyaas/graph`, data);
};
export const GET_PARIKSHA_STUDENTS_GRAPH_DATA = (data: any): Promise<any> => {
  return API.post(`${apiUrl}/pariksha/graph`, data);
};
export const GET_PARIKSHA_STUDENTS_SUBJECT_ANALYSIS = (
  data: any
): Promise<any> => {
  return API.post(`${apiUrl}/subjectwise/analysis`, data);
};
