import { DataTableCol } from "../../../components/data-table/types";

export const SubscriptionGridCols: DataTableCol[] = [
  {
    title: "Name",
    control: "name",
    canShowColumn: true,
  },
  {
    title: "Price Per day",
    control: "price_per_day",
    canShowColumn: true,
  },
  {
    title: "Annual Price",
    control: "membership_amount",
    canShowColumn: true,
  },
  {
    title: "Discount (%)",
    control: "discount_percentage",
    canShowColumn: true,
  },
  {
    title: "Final Price",
    control: "final_price",
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "both",
    isEdit: true,
    isView: true,
    canShowColumn: true,
  },
];
