import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_PARENT_BY_CHILDRENS, GET_USER_USER_CODE } from "../../../app/service/users.service";
import appConfig, { UI_BASE_URL } from "../../../config/constant";
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'
import FATHERIMG from '../../../institute-dashboard-assets/images/father-icon.svg'
import MOTHERIMG from '../../../institute-dashboard-assets/images/mother-icon.svg'
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE, UPDATE_PAYMENT } from "../../../app/service/payment-service";
import { CREATE_MNS_USER_ASSIGN } from "../../../app/service/mns-service";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_STATUS_BY_STUDENT } from "../../../app/service/subscription.service";
import { GET_SUBSCRIPTION_BY_USER_CODES } from "../../../app/service/user-subscription";
import SUBJECTCOMPLETE from '../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import { CREATE_CCAVENUE_PAYMENT } from "../../../app/service/ccavenue.service";
import MNSLOGO from '../../../institute-dashboard-assets/images/mns_logo.png'
import ABHYAASLOGO from '../../../institute-dashboard-assets/images/Abyas-logo.svg'
import PARIKSHALOGO from '../../../institute-dashboard-assets/images/Pariksha-logo.svg'
import ProfileImageUpload from "../../../components/image-crop/profile-img-upload";

export const ParentInformationDashboard = () => {
    let id = localStorage.getItem("user_id");
    const schoolId = Number(id);
    const schoolsCode = localStorage.getItem("school_code");
    const studentsCode = localStorage.getItem("user_code");
    const [childernList, setChildernList] = useState<any>([]);
    const location = useLocation();
    const history = useHistory();
    const [studentList, setStudentList] = useState<any>([]);
    const [moduleOpted, setModuleOpted] = useState<any>([]);
    const [modulesPurchased, setModulesPurchased] = useState<any>([]);
    const [childrenLoading, setChildrenLoading] = useState(false);
    const [parentInfo, setParentInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [subscriptionPrice, setSubscriptionPrice] = useState<any>("");
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [amount, setAmount] = useState<any>("");
    const [moduleName, setModuleName] = useState<any>("");
    const [subscription, setSubscription] = useState<any>([]);
    const [moduleData, setModuleData] = useState<any>([]);
    const [onSelectModule, setOnSelectModule] = useState<any>({});
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const Razorpay = useRazorpay();
    const RAZORPAY_OPTIONS: RazorpayOptions = {
        key: appConfig.paymentKey,
        amount: (amount + "000"),
        currency: "INR",
        name: "IAMSAMARTH",
        description: "Subscription Fee",
        image: "",//MSB LOGO
        order_id: "",

        prefill: {
            name: moduleName,//SCHOOL CODE OR MODULE NAME
            email: "",
            contact: "9999999999",
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#3399cc",
        },
    };

    useEffect(() => {
        setLoading(true);
        GET_USER_USER_CODE(studentsCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                setParentInfo(data);
            }
        });

        getSchools();
        // getSubscriptionDetails(studentsCode);

        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_paymet_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }

            } else {
            }
        }

    }, []);
    function getSubscriptionDetails(data: any) {
        setLoading(true);
        GET_SUBSCRIPTION_BY_USER_CODES(data).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.data[0];
                setSubscription(data)
                setLoading(false);

            }
        });
    }

    function getSchools() {
        setLoading(true);
        GET_PARENT_BY_CHILDRENS(studentsCode, 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data.forEach((element: any) => {
                        element.student_code = studentsCode;
                        if (element.type === 'individual') {
                            element.name_of_school = element?.indv_school_details?.school_name;
                        }
                    });
                    setStudentList(data);
                    if (data.length > 0) {
                        localStorage.setItem("cc_student_paymet_info", JSON.stringify(data[0]));
                        setChildernList(data[0]);
                    }
                    const userRole = localStorage.getItem("user_role");
                    getModulesByUserCode(data[0].user_code, userRole);


                }
            }
        });
    }

    const getModulesByUserCode = (userCode: any, userRole: any) => {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {
            if (res) {
                if (res.status === "fail") {
                    toast.error(res.message);
                } else {
                    let data = res;
                    if (Array.isArray(data)) {
                        let prepared = data?.filter((el: any) => el?.module_code !== 'CAS-PARENT');
                        setModuleData(prepared);
                        if (userRole === "PARENT") {
                            let opted = prepared.filter(
                                (el: any) => el.amount_status === "COMPLETED"
                            );
                            setModuleOpted(opted);
                            let purchased = prepared.filter(
                                (el: any) => el.amount_status !== "COMPLETED"
                            );
                            setModulesPurchased(purchased)
                        }
                    }
                    setChildrenLoading(false);
                    setLoading(false);
                    setChildrenLoading(false);
                }
            }
        });
    };

    const openTabsData = (data: any, i: number) => {
        setChildrenLoading(true);
        localStorage.setItem("cc_student_paymet_info", JSON.stringify(data));
        setChildernList(data);
        const userRole = localStorage.getItem("user_role");
        getModulesByUserCode(data.user_code, userRole);

    }

    const openTest = (data: any) => {
        localStorage.setItem("moduleUuid", data.uuid);
        setOnSelectModule(data)
        if (data.module_code === "CAS") {
            if (parentInfo?.gender === "female") {
                if (data.parent_details.mother?.module_status === "completed") {
                    history.push(`/parent-dashboard/view/servy-success/${data.uuid}/${studentsCode}/${data.user_details.user_code}`)
                }
                else {
                    history.push(`/parent-dashboard/view/servey-instructions/${data.user_details.user_code}/${data.module_code}`)
                }
            }
            if (parentInfo?.gender === "male") {

                if (data.parent_details.father?.module_status === "completed") {
                    history.push(`/parent-dashboard/view/servy-success/${data.uuid}/${studentsCode}/${data.user_details.user_code}`)
                }
                else {
                    history.push(`/parent-dashboard/view/servey-instructions/${data.user_details.user_code}/${data.module_code}`)
                }
            }
        }

    }
    const handleclose = () => {
        setCanShowPopup(false);
    }
    const handlePayment = () => {
        createPayment(moduleInfo, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleInfo));

    }
    function createPayment(moduleInfo: any, paymentInfo: any) {


        const payload = {
            school_code: childernList?.name_of_school,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }

        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, childernList);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id);
        if (amount > 0) {
            createCcavenuePayment(amount, txn_id);
        } else {
            onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success", moduleInfo);
            if (amount > 0) {
                updateSubscription(txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo, "success");
            }
        }
    }
    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData?.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.message);
            } else {
                setCanShowPopup(false);
                toast.success("Successfully assigned  module!");
                removeItemsFromeLocalStorage();
                const userRole = localStorage.getItem("user_role");
                setChildrenLoading(true);
                getModulesByUserCode(studentInfo?.user_code, userRole);
            }
        });
    }
    function onUpdatePayment(transactionId: string, data: any, status: string, moduleData: any) {

        const payload = {
            txn_id: transactionId,
            "module_code": moduleData.module_code ? moduleData.module_code : '',
            "module_uuid": '',
            "txn_details": data,
            "status": status
        }

        UPDATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (status === "success") {
                        toast.success('Payment Updated Successfully');
                        setCanShowPopup(false);
                        getAssignModule(paymentInfo, moduleData, childernList);
                    }
                }
            }
        });
    }
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": studentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);

        }

    }
    function onClickPay(data: any) {
        setPaymentInfo({});
        setModuleInfo(data);
        setModuleName(data.module_name);
        getPaymentDetailsOfUser(data);
        setCanShowPopup(true);
    }
    function getPaymentDetailsOfUser(data: any) {
        const payLoad = {
            "institute_school_code": childernList?.institute_school_code,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${childernList?.user_code}` }],
            "module_uuid": data?.uuid
        }
        setPaymentDetailsLoading(true);
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
                setPaymentDetailsLoading(false);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setAmount(data[0]?.total_amount);
                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });
    }

    function onClickViewReports(item: any) {
        if (item.module_code === "MATHS") {
            history.push(`/parent-dashboard/view/maths/reports/student/${item.user_details.user_code}`);
        } else if (item.module_code === "ABHYAAS") {
            history.push(`/parent-dashboard/view/abhyaas/initiative/${item.uuid}/${item?.user_details?.user_code}/${childernList?.institute_school_code}`);
        } else if (item.module_code === "PARIKSHA") {
            history.push(`/parent-dashboard/view/pariksha/initiative/${item.uuid}/${item?.user_details?.user_code}/${childernList?.institute_school_code}`);

        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/parent-dashboard/view`,
            cancel_url: `${UI_BASE_URL}/parent-dashboard/view`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_paymet_info', 'cc_subscription_info', 'cc_user_payment_info', 'cc_subscription_price', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    return (

        <div>

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscription-payment-dialog" role="document">
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {paymentDetailsLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!paymentDetailsLoading && <div className=" custom-scroll table-responsive">
                                <table className="table table_new_ui fixed-table-header mb-0">
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Module Name</th>
                                            <th>Subscription Price</th>
                                            <th>Module Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {Object.keys(paymentInfo).length > 0 && <tbody>
                                        <tr>
                                            <td>{paymentInfo?.name}</td>
                                            <td>{(moduleInfo.module_code === "CAS" || moduleInfo.module_code === "MATHS") ? moduleInfo?.module_code : moduleInfo?.module_code?.charAt(0).toUpperCase() + moduleInfo?.module_code?.slice(1).toLowerCase()}</td>
                                            <td>{(paymentInfo?.subscription_payment_status === "completed") ? <span>-</span> : <span>{parseInt(paymentInfo?.subscription_price)}</span>}</td>
                                            <td>{paymentInfo?.module_price}</td>
                                            <td>{paymentInfo?.total_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Total Amount to Pay</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ fontWeight: 'bold' }}>{paymentInfo?.total_amount}</td>
                                        </tr>
                                    </tbody>}
                                </table>
                                {Object.keys(paymentInfo).length == 0 && <p className="text-center f12 mt-3">No Data Found</p>}
                            </div>}
                        </div>
                        <div className="modal-footer">
                            {(Object.keys(paymentInfo).length > 0) && <button type="button" className="btn btn-primary cursor-pointer" onClick={handlePayment}>{(parseInt(paymentInfo?.total_amount)) > 0 ? 'Pay Now' : 'Assign'}</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5 mt-5">
                    <div className="spinner-border mt-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 pt-0 px-0 border-bottom">
                            <li className="breadcrumb-item"><a>Dashboard</a></li>
                        </ol>
                    </nav>
                    <div className="border-bottom pb-3">
                        <div className="d-md-flex sc-profile-details">
                            <div className="mb-3 mb-md-0">
                                <ProfileImageUpload
                                    profileImg={parentInfo.profile_img}
                                    currentUserData={parentInfo}
                                />
                            </div>
                            <div className="sc-profile-text ps-md-4">
                                <h2 className="mt-0 mb-2">{parentInfo?.name?.charAt(0).toUpperCase() + parentInfo?.name?.slice(1)}</h2>
                                <p className="m-0 sc-profile-contact">
                                    <span>{parentInfo.mobile_number}</span>{parentInfo.mobile_number && parentInfo.email_id && <span className="px-2">|</span>}<span>{parentInfo.email_id}</span>
                                </p>
                                <p className="m-0 sc-profile-addrs">
                                    <span>{parentInfo.state}</span> {parentInfo.state && parentInfo.district && <span>|</span>} <span>{parentInfo.district}</span> {parentInfo.district && parentInfo.city_village && <span className="pe-1">|</span>}
                                    <span>{parentInfo.city_village}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <h3 className="mt-4 mb-3 section-hd">Children Details</h3>
                    <ul className="nav nav-tabsnewui  custom-tabsnewui mt-4" id="myTab" role="tablist">
                        {studentList?.map((item: any, i: number) => {
                            return (
                                <li className="nav-item" role="presentation" key={i} onClick={() => openTabsData(item, i)}>
                                    <button
                                        className={`nav-link ${i === 0 && 'active'} pr`}
                                        id="child1-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#child1"
                                        type="button"
                                        role="tab"
                                        aria-controls="child1"
                                        aria-selected="true"
                                    >
                                        {i + 1}.

                                        {item?.name?.charAt(0).toUpperCase() + item?.name?.slice(1)}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                    <div className="tab-content pt-3" id="myTabContent">
                        {childrenLoading &&
                            <div className="text-center p-5 mt-5">
                                <div className="spinner-border mt-5" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!childrenLoading &&
                            <div className="tab-pane fade show active" id="child1">
                                <div className="row pb-3">
                                    <div className="col-md-6">
                                        <div className="d-flex sc-profile-details small mb-4">
                                            <div className="sc-profile-img pt-0">
                                                {childernList?.gender === "female" ? <img
                                                    src={childernList?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${childernList?.profile_img.charAt(0) === "/" ? childernList?.profile_img.substring(1) : childernList?.profile_img}` : MOTHERIMG}
                                                    alt=""
                                                    className="pr-image"
                                                /> : <img
                                                    src={childernList?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${childernList?.profile_img.charAt(0) === "/" ? childernList?.profile_img.substring(1) : childernList?.profile_img}` : FATHERIMG}
                                                    alt=""
                                                    className="pr-image"
                                                />}
                                            </div>
                                            <div className="sc-profile-text ps-4">
                                                <p className="sc-profile-deg mb-1">
                                                    Student | <span className="text-dgray">DOB  -  {childernList?.date_of_birth && childernList?.date_of_birth.slice(0, 11).replace(/-/g, "/")}</span>
                                                </p>
                                                <h2 className="m-0">
                                                    {childernList?.name?.charAt(0).toUpperCase() + childernList?.name?.slice(1)} | <span>{childernList?.standard}th Class</span>
                                                </h2>
                                                <p className="m-0 sc-profile-contact">
                                                    <span>{childernList?.mobile_number}</span> |
                                                    <span className="ps-1">{childernList?.email_id}</span>
                                                </p>
                                                <p className="m-0 sc-profile-addrs">
                                                    <span>{childernList?.state}</span>{childernList?.district && <span>|</span>} <span>{childernList?.district}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {moduleOpted?.length > 0 && <h3 className="mb-3 section-hd">Modules Opted</h3>}
                                <div className="row mb-4">
                                    <div className="module_list_cards d-flex justify-content-center justify-content-md-start">
                                        {moduleOpted?.map((data: any, i: number) => {
                                            return (
                                                <div key={i} className={`module_card_view  psurvey  position-relative ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "cardTwo"} ${data?.module_code === "ABHYAAS" && "cardThree"}`} onClick={() => openTest(data)}>
                                                    <span className={`text-dgray  ${(data?.module_code === "CAS" || data?.module_code === "CAS-PARENT") && "survey-link cursor-pointer"}`}>
                                                        {data.module_code === "MATHS" && <img className=" module_logo_img" src={MNSLOGO} alt="" />}
                                                        {data.module_code === "ABHYAAS" && <img className="  module_logo_img" src={ABHYAASLOGO} alt="" />}
                                                        {data.module_code === "PARIKSHA" && <img className=" module_logo_img" src={PARIKSHALOGO} alt="" />}
                                                        <div>
                                                            {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                                            {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                                            {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                                            {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                                            {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                                            {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                                            {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                                        </div>
                                                        <p className="mb-2">
                                                            Payment - <label >{(data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess">Pending</span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label>
                                                        </p>
                                                        {data?.module_code === "CAS" && <p className="m-0">
                                                            Status -
                                                            <label className={`${data?.module_status === "completed" ? "text-completed" : "text-inprogess"} px-2`}>Student</label>
                                                            <label className={`${data.parent_details.father?.module_status === "completed" ? "text-completed" : "text-inprogess"} px-2`}>Father</label>
                                                            <label className={`${data.parent_details.mother?.module_status === "completed" ? "text-completed" : "text-inprogess"} px-2`}>Mother</label>
                                                        </p>}
                                                        {data?.module_code !== "CAS" && <p className="m-0">
                                                            Status -
                                                            <label className={`${data?.module_status === "completed" ? "text-completed" : "text-inprogess"} px-2`}>{data?.module_status === "completed" ? "Complete" : "In Progress"}</label>
                                                        </p>}
                                                        {(data?.module_status === "completed" && data?.module_code === "MATHS") &&
                                                            <img
                                                                src={SUBJECTCOMPLETE}
                                                                alt=""
                                                                className="module-completed-logo"
                                                            />
                                                        }
                                                        {(data?.module_status === "completed" || (data?.module_code !== "CAS" && data.amount_status === "COMPLETED")) &&
                                                            <p className="m-0"> <span className="btn btn-continue mt-2" onClick={() => onClickViewReports(data)}> View Report </span></p>
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {modulesPurchased?.length > 0 && <h3 className="mb-3 section-hd">Modules Available for Purchase</h3>}
                                <div className="row">
                                    <div className="module_list_cards d-flex justify-content-center justify-content-md-start">
                                        {modulesPurchased?.map((data: any, i: number) => {
                                            return (
                                                <div key={i} className={`module_card_view psurvey  position-relative ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "cardTwo"} ${data?.module_code === "ABHYAAS" && "cardThree"}`}>
                                                    <span className="text-dgray">
                                                        {/* <h2 className={`module-title  ${data.module_code !== "CAS" ? "mb-4" : "mb-0"}`}>{data.module_code}</h2> */}
                                                        {data.module_code === "MATHS" && <img className=" module_logo_img" src={MNSLOGO} alt="" />}
                                                        {data.module_code === "ABHYAAS" && <img className="  module_logo_img" src={ABHYAASLOGO} alt="" />}
                                                        {data.module_code === "PARIKSHA" && <img className=" module_logo_img" src={PARIKSHALOGO} alt="" />}
                                                        <div>
                                                            {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                                            {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                                            {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                                            {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                                            {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                                            {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                                            {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                                        </div>
                                                        <p className="mb-2">
                                                            Payment - <label >{(data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess">Pending</span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label>
                                                        </p>

                                                        {(data.amount_status === "PENDING" || data.amount_status === null) ?
                                                            <p className="m-0"><span className="btn btn-pay"
                                                                onClick={() => onClickPay(data)}
                                                            >Pay Now</span></p>

                                                            :
                                                            <p className="m-0">
                                                                Status -
                                                                {data.module_status === "completed" && <label className="text-completed px-2">Student</label>}
                                                                {(data.module_status === "inprogress" || data.module_status === null) && <span><label className="text-inprogess px-2">Father</label>
                                                                    <label className="text-inprogess px-2">Mother</label></span>}
                                                            </p>
                                                        }

                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>}

                    </div>
                </div>}

        </div>
    )
}