import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import { GET_STANDARD_DIVISION_BY_SCHOOL_CODE } from "../../app/service/school.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from "../../app/service/payment-service";
import { APPLY_COUPON_CODE, FECTH_PROMOCODES_BY_PROMOTIONS_FILTERS, UPDATE_APPLIED_COUPON_CODE } from "../../app/service/promotions";
import { CREATE_CCAVENUE_PAYMENT } from "../../app/service/ccavenue.service";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../app/service/subscription.service";
import FormBuilder from "../form-builder";

interface PaymentProps {
    schoolCode: any;
    instituteCode: any;
    dashBoard?: any;
    cancelURL: any;
    redirectURL: any;
}

export const SchoolUserPayments: React.FC<PaymentProps> = (props: PaymentProps) => {
    const assignUserForm = useRef<any>({});
    const [studentLoading, setStudentLoading] = useState(false);
    const [couponLoading, setCouponLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [assignUserData, setAssignUserData] = useState<any>({});
    const [standardList, setStandardList] = useState<any>([]);
    const [divisionList, setDivisionList] = useState<any>([]);
    const [studentList, setStudentList] = useState<any[]>([]);
    const [optionStudentsLoading, setOptionStudentsLoading] = useState(false);
    const [studentsSelected, setStudentsSelected] = useState<any>([]);
    const [moduleData, setModuleData] = useState<any>({});
    const [paymentData, setPaymentData] = useState<any>({});
    const [couponData, setCouponData] = useState<any>({});
    const [showCouponDiv, setShowCouponDiv] = useState<boolean>(false);
    let instituteCode: any = "";
    const [insCode, SetInsCode] = useState<any>("");
    const loginUserCode = localStorage.getItem("user_code");
    const [couponCodeValue, setCouponCodeValue] = useState<any>("");
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [applyDiscount, setApplyDiscount] = useState<boolean>(false);

    const schoolsCode = props?.schoolCode;


    useEffect(() => {
        // ccvenue//
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const preparedSubscriptionData = JSON.parse(localStorage.getItem('cc_subscription_data') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const paymentInfo = JSON.parse(localStorage.getItem('cc_payment_info') || '{}');
                const couponsData = JSON.parse(localStorage.getItem("cc_coupons_data") || '{}');
                const selectedUsers = JSON.parse(localStorage.getItem('cc_selected_users') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", preparedSubscriptionData, statusValue, couponsData);
                } else {
                    removeItemsFromeLocalStorage();
                }

            } else {
            }
        }
        fetchAllData();


    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            await getStudentsByStandardDivision();
            await getPaymentDetails();
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    };



    const getPaymentDetails = async (data?: any, appiledDiscount?: boolean) => {

        const payLoad = {
            "institute_school_code": schoolsCode,
            "standard": data?.standard ? data?.standard : "",
            "division": data?.division ? data?.division : "",
            "students": data?.students ? data?.students : [],
            "module_uuid": "",
            discount_applied: appiledDiscount ? "true" : "false"
        }
        setShowCouponDiv(false);
        try {
            const res: any = await CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad);
            if (res.status === "fail") {
                toast.error(res?.message);
                setStudentLoading(false);
            } else {
                if (res) {
                    setPaymentData(res);
                    let data = res?.students;
                    localStorage.setItem("cc_subscription_data", JSON.stringify(data));
                    if (appiledDiscount) {
                        setApplyDiscount(true);
                    }
                    let preparedData = [];
                    const userCodes = [];
                    for (let k = 0; k < data?.length; k++) {
                        preparedData.push({
                            label: data[k].name,
                            value: data[k].user_code
                        });
                        userCodes.push(data[k].user_code);
                    }
                    // let preUser = userCodes?.slice(0, 500);
                    localStorage.setItem("cc_selected_users", JSON.stringify(preparedData));
                    if (payLoad?.students?.length == 0) {
                        setStudentList(preparedData);
                    }
                    if (res?.total_amount > 0) {
                        let preElement = {
                            students_count: res?.total_students,
                            total_amount: res?.total_amount,
                        }
                        // await getPromoCodes(preElement);
                    }
                    setStudentLoading(false);
                    // setLoading(false);
                }
            }
        } catch (error) {

        }
    }

    const getStudentsByStandardDivision = async () => {
        try {
            const res: any = await GET_STANDARD_DIVISION_BY_SCHOOL_CODE(schoolsCode);
            if (res.status === "fail") {
                toast.error(res?.err);
            } else {
                if (res) {
                    let data = res;
                    if (Array.isArray(data)) {
                        const { classes, sections } = data.reduce(removeDuplicatesList, { classes: [], sections: [] });
                        sections?.sort((a: string, b: string) => a > b ? 1 : -1);
                        setStandardList(classes);
                        setDivisionList(sections);
                    }
                }
            }
        } catch (error) {

        }

    }

    const removeDuplicatesList = (accumulator: any, currentValue: any) => {
        if (!accumulator.classes.includes(currentValue.class)) {
            accumulator.classes.push(currentValue.class);
        }
        if (!accumulator.sections.includes(currentValue.section)) {
            accumulator.sections.push(currentValue.section);
        }
        return accumulator;
    };
    const getPromoCodes = async (payMentInfo: any) => {
        const payLoad = {
            promotion_type: "IAS",
            expand: "promocodes"
        }
        try {
            const res: any = FECTH_PROMOCODES_BY_PROMOTIONS_FILTERS(0, payLoad);
            if (res.status === "fail") {
                toast.error(res?.error);
                setStudentLoading(false);
                setLoading(false);
            } else {
                if (res) {
                    const data = res?.rows[0]?._promocodes[0];
                    if (data?.promo_code) {
                        let preparedData = {
                            coupon_code: data?.promo_code,
                            students_count: payMentInfo?.students_count,
                            total_amount: payMentInfo?.total_amount,
                        }
                        applyCoupon(preparedData);
                    } else {
                    }
                }
            }
        } catch (error) {

        }
    }

    const handleInput = (data: any) => {
        setAssignUserData(data);

    }

    const handleStandardChange = (e: any) => {
        setStudentsSelected([]);
        const data = { ...assignUserData.value };
        data.standard = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        setStudentLoading(true);
        getPaymentDetails(preparedData);

    }

    const handleDivisionChange = (e: any) => {
        setStudentsSelected([]);
        const data = { ...assignUserData.value };
        data.division = e.target.value;
        let preparedData = {
            standard: data.standard,
            division: data.division,
        }
        setStudentLoading(true);
        getPaymentDetails(preparedData);


    }
    const onSelectedStudent = (e: any) => {
        if (e) {

            setStudentsSelected(e);
            localStorage.setItem("cc_selected_users", JSON.stringify(e));
            const data = { ...assignUserData.value };
            const selectUserCodes = [];
            const userCodes = [];
            for (let i = 0; i < e?.length; i++) {
                userCodes.push({ user_code: `${e[i]?.value}` });
                selectUserCodes.push(`${e[i]?.value}`);
            }

            let preparedData = {
                standard: data.standard,
                division: data.division,
                students: userCodes
            }

            setStudentLoading(true);
            getPaymentDetails(preparedData);
        }
    }


    function handleRefresh() {
        const data = assignUserData;
        data.value = {};
        setStudentsSelected([]);
        setAssignUserData({ ...data });
        assignUserForm.current.reset();
        setStudentLoading(true);
        getPaymentDetails();
    }

    const handlePayment = () => {
        createPayment(moduleData, paymentData);
    }
    function createPayment(data: any, subscriptionData: any) {
        let subscriptionAmount = paymentData?.discount_applied ? paymentData?.discounted_amount : paymentData?.total_amount;
        const payload = {
            school_code: schoolsCode,
            // user_ids: selectedUsers,
            user_ids: [],
            sub_amount: couponData?.final_amount ? Math.round(couponData?.final_amount) : subscriptionAmount
        }
        CREATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (couponData?.final_amount == 0 && Object.keys(couponData?.rule_applied).length > 0 || payload?.sub_amount == 0) {
                        updateSubscription(res.txn_id, { "message": "SUM AMOUNT ZERO" }, paymentData?.students, "success", couponData);
                    }
                    else {
                        localStorage.setItem("cc_transaction_id", res?.txn_id)
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, data, subscriptionData);
                    }
                }
            }
        });

    }
    function onOpenPaymentInfo(amount: any, txn_id: string, data: any, subscriptionData: any) {
        createCcavenuePayment(amount, txn_id);
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: props?.cancelURL,
            cancel_url: props?.redirectURL,

        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                // return
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function updateSubscription(transactionId: any, transactionData: any, subscriptionData: any, paymentStatus: any,
        couponData?: any) {
        const preparedData = [];
        for (let i = 0; i < subscriptionData?.length; i++) {
            preparedData.push({
                "uuid": "",
                "school_code": schoolsCode,
                "user_code": subscriptionData[i].user_code,
                "transaction_id": transactionId,
                "amount": subscriptionData[i].subscription_price,
                // "transaction_details": JSON.stringify(transactionData),
                "transaction_details": "",
                "payment_status": "completed"
            })
        }
        const payload = {
            data: preparedData
        }
        if (paymentStatus === "success") {
            ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (Object.keys(couponData?.rule_applied).length > 0) {
                        updateAppliedCoupon(transactionId, couponData);
                    }
                    toast.success('Subscription Added Successfully');
                    removeItemsFromeLocalStorage();
                    fetchAllData();

                }
            });
        } else {
            fetchAllData();

        }

    }


    function updateAppliedCoupon(txnId: any, couponData?: any) {
        const payload = {
            promotion_uuid: couponData?.promotion_uuid ? couponData?.promotion_uuid : "",
            promo_code_uuid: couponData?.promocode_uuid ? couponData?.promocode_uuid : "",
            applied_for_user_code: loginUserCode,
            applied_sponsor_uuid: "",
            applied_dt: todayDate,
            applied_rules: couponData,
            status: 2
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_APPLIED_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
        }
    }

    function applyCoupon(data?: any, type?: number) {
        const payload = {
            promo_code: data?.coupon_code ? data?.coupon_code : couponCodeValue?.replace(/\s/g, ''),
            students_count: data?.students_count ? data?.students_count : paymentData?.total_students,
            payable_amount: data?.total_amount ? data?.total_amount : paymentData?.total_amount,
            institute_code: instituteCode ? instituteCode : props?.instituteCode,
            school_code: schoolsCode
            //  schoolsCode
        }
        if (payload?.promo_code) {
            if (type === 0) {
                setCouponLoading(true);
            }
            setCouponData({});
            setShowCouponDiv(false);
            APPLY_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    if (type === 0) {
                        toast.error(res?.err);
                    }
                    setStudentLoading(false);
                    setCouponLoading(false);
                } else {
                    let data = res
                    if (Object.keys(data?.rule_applied).length > 0) {
                        setShowCouponDiv(true);
                        localStorage.setItem("cc_coupons_data", JSON.stringify(data));
                        data.coupon_code = payload?.promo_code;
                        setCouponData(data);
                    } else {
                        // toast.error("Coupon is not applicable");
                    }
                    setStudentLoading(false);
                    setCouponLoading(false);
                }
            });
        }

    }
    function onApplyCouponValue(e: any) {
        if (e) {
            setCouponCodeValue(e.target.value);
        } else {
            setCouponData({});
            setCouponCodeValue("");
        }

    }

    const removeCouponData = (removeType?: string) => {
        setApplyDiscount(false);
        setCouponData({});
        setCouponCodeValue("");
        localStorage.removeItem("cc_coupons_data");
        setShowCouponDiv(false);
        if (removeType === "discount") {
            const data = { ...assignUserData.value };
            const userCodes = [];
            for (let i = 0; i < studentsSelected?.length; i++) {
                userCodes.push({ user_code: `${studentsSelected[i]?.value}` });
            }
            let preparedData = {
                standard: data.standard,
                division: data.division,
                students: userCodes
            }
            setStudentLoading(true);
            getPaymentDetails(preparedData);

        }
    }
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['student_paymet_info', 'subscription_info', 'cc_subscription_data', 'subscription_price', 'cc_transaction_id', 'module_info', 'cc_selected_users', 'cc_coupons_data', 'cc_payment_info', 'cc_module_uuid'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const handleApplyDiscount = () => {
        const data = { ...assignUserData.value };
        const userCodes = [];
        for (let i = 0; i < studentsSelected?.length; i++) {
            userCodes.push({ user_code: `${studentsSelected[i]?.value}` });
        }
        let preparedData = {
            standard: data.standard,
            division: data.division,
            students: userCodes
        }
        setStudentLoading(true);
        getPaymentDetails(preparedData, true);
    }
    const getDiscountStatus = () => {
        if (paymentData?.discount_applied && (paymentData?.discounted_amount || paymentData?.discounted_amount == 0)) {
            return true
        } else {
            return false
        }
    }

    return (
        <div className="mt-4">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <FormBuilder onUpdate={handleInput}>
                        <form ref={assignUserForm}>
                            <div className="row ">
                                <div className="col-md-3 ">
                                    <div className="mb-4">
                                        <label className="form-label mb-0">Standard</label>
                                        <select className="form-select" name="standard" onChange={(e) => { handleStandardChange(e) }}>
                                            <option value="">Select Standard</option>
                                            {standardList?.map((item: any) => (
                                                <option value={item}>{item}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-4 ">
                                        <label className="form-label mb-0">Division</label>
                                        <select className="form-select" name="division" onChange={(e) => { handleDivisionChange(e) }}>
                                            <option value="">Select Division</option>
                                            {divisionList?.map((item: any) => (
                                                <option value={item}>{item}</option>
                                            ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className={`col-md-3 `}>
                                    <div className="mb-4 position-relative">
                                        <label className="form-label mb-0">Student</label>
                                        {optionStudentsLoading &&
                                            <div className=" mt-1 pt-1 position-absolute " style={{ right: "8px", zIndex: "9999" }}>
                                                <div className="spinner-border ms-5 ms-md-0" role="status" style={{ height: "23px", width: "23px" }} >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>}
                                        <MultiSelect
                                            options={studentList}
                                            value={studentsSelected}
                                            onChange={(e: any) => onSelectedStudent(e)}
                                            labelledBy="Select"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-4 mt-md-3 ">
                                        <button className="btn clear-btn cursor-pointer mt-2" type="button" onClick={() => handleRefresh()}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormBuilder>
                    {studentLoading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {!studentLoading &&
                        <div>
                            <div className={`mar-t-20 custom-scroll table-responsive ${paymentData?.students?.length > 10 && "tableFixHead "}`}>
                                <table className={`table ${props?.dashBoard ? " table_new_ui" : "fixed-table-header"} mb-0`}>
                                    <thead className=''>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Student Name</th>
                                            <th className="text-center">Membership Amount</th>
                                            {/* <th className="text-center">Total Amount </th> */}
                                        </tr>
                                    </thead>
                                    <tbody className=''>
                                        {paymentData?.students?.map((name: any, i: number) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{name?.name}</td>
                                                <td className="text-center">{name?.subscription_payment_status === "completed" ? "-" : name?.subscription_price}</td>
                                                {/* <td className="text-center">{name?.total_amount}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {paymentData?.students?.length > 0 && <div className='d-flex justify-content-around py-2 ' style={{ backgroundColor: '#e6e6e6', margin: '0px' }}>
                                <div className='fw-bold' >Total Amount to Pay</div>
                                <div className={` fw-bold ${props?.dashBoard ? "ps-md-0" : "ps-md-4 ms-4"} `} >
                                    <h5 className="f18"> {getDiscountStatus() && <del className="me-3">&#x20b9;{paymentData?.total_amount}</del>} <span className={`${getDiscountStatus() && "py_grn_cls"}`}>&#x20b9;{(paymentData?.discount_applied && (paymentData?.discounted_amount || paymentData?.discounted_amount == 0)) ? paymentData?.discounted_amount : paymentData?.total_amount}</span></h5>
                                </div>
                            </div>}
                            {paymentData?.students?.length > 0 && <div className='d-flex justify-content-center p-3 mb-2 ' >
                                <button type="button" className="btn btn-primary cursor-pointer text-center border border-dark" onClick={handlePayment}>{(paymentData?.total_amount > 0 && couponData?.final_amount !== 0 && paymentData?.discounted_amount > 0) ? 'Pay Now' : 'Assign'}</button>
                            </div>}
                            {paymentData?.students?.length == 0 && <p className="f16 text-center mt-2">No Data Found</p>}
                            {(parseInt(paymentData?.instant_discount) > 0 &&
                                paymentData?.total_amount > 0) && <div className="d-flex justify-content-center">
                                    <div className="d-flex mt-3 me-2">
                                        {applyDiscount && <h5 className="f16 apl_text_cl d-inline-block mt-1">{paymentData?.instant_discount}%  discount applied successfully!! </h5>}
                                        {!applyDiscount && <h5 className="f16 apl_text_cl d-inline-block mt-1">You can apply for an Instant discount of {paymentData?.instant_discount}%</h5>}
                                        <button className="btn btn-success px-4 py-2 ms-3 f14 fw-bold" onClick={handleApplyDiscount} disabled={Object.keys(couponData).length > 0 || applyDiscount}>Apply</button>
                                        {applyDiscount && <button type="button" className="btn btn-success cursor-pointer  pt-1  ms-4" onClick={() => removeCouponData("discount")}>Remove</button>}
                                    </div>
                                </div>}
                            {(parseInt(paymentData?.instant_discount) > 0 &&
                                paymentData?.total_amount > 0) && <p className="f16 text-center mb-0">OR</p>}
                            {paymentData?.total_amount > 0 &&
                                <div className={`row mt-1 justify-content-md-center ${Object.keys(couponData).length == 0 && "mb-5"}`} >
                                    <div className='col-md-5 col-12 d-flex'>
                                        <input className='form-control form-control-lg f14 mt-4' placeholder=' Coupon Code' type='text' value={couponCodeValue} disabled={applyDiscount} onChange={(e) => onApplyCouponValue(e)}></input>
                                        <button type="button" className="btn btn-primary cursor-pointer mt-4 pt-1 ms-2 px-4 py-1" onClick={() => applyCoupon("", 0)} disabled={applyDiscount}>Apply</button>
                                        {Object.keys(couponData).length > 0 && <button type="button" className="btn btn-success cursor-pointer mt-4  pt-1 px-3 py-1 ms-2" onClick={() => removeCouponData()}>Remove</button>}
                                    </div>
                                </div>
                            }
                            {couponLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!couponLoading &&
                                <div>
                                    {(showCouponDiv && parseInt(paymentData?.total_amount) > 0) &&
                                        <div className="row   mt-4 mb-5  justify-content-md-center" >
                                            <div className="col-md-5 col-12  py-3 px-4 rounded-3" style={{ backgroundColor: '#e6e6e6', margin: '0px' }}>
                                                <h3 className="dkpi-hd f18 mb-3 text-center">Coupon Applied</h3>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Coupon Code</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.coupon_code}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Students Count</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.students_count}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Payable Amount</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.payable_amount}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">

                                                    <div className="col ">
                                                        <p className="mb-0">Discount Percentage</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.discount_percentage?.toFixed()}%
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className=" mb-0">Discount Amount</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.discount_amount?.toFixed()}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between  mt-2">
                                                    <div className="col">
                                                        <p className="text-gray f18 fw-bold mb-0"> Final Amount  to Pay</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        <span className="f16 fw-bold">&#x20b9;{Math.round(couponData?.final_amount)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>}
                </div>}
        </div>
    )
};