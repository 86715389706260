import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Breadcum } from "../../../components/breadcum";
import { GET_SCHOOL_BY_ID } from "../../../app/service/school.service";
import { UI_BASE_URL } from "../../../config/constant";
import { SchoolUserPayments } from "../../../components/payments/school-users";

export const StudentPayments: React.FC = () => {
    const [loading, setLoading] = useState(false);
    let instituteCode: any = "";
    const [insCode, SetInsCode] = useState<any>("");
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolId = Number(id);
    const schoolsCode = schoolCode;
    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "Schools",
            value: "Schools",
            routerLink: "/dashboard/school/list",
            isActive: false,
        },
        {
            label: "School Name",
            value: "",
            routerLink: `/dashboard/school/info/${schoolId}/paymentlist/${schoolCode}`,
            isActive: false,
        },
        {
            label: "subscription Name",
            value: "Annual Membership Amount",
            routerLink: "",
            subName: "(Payment)",
            isActive: true,
        },
    ]);

    useEffect(() => {
        fetchAllData();
    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            await getSchoolInfo();
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    };


    const getSchoolInfo = async () => {
        try {
            const res: any = await GET_SCHOOL_BY_ID(schoolId)
            if (res.status === "fail") {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res.rows[0];
                    instituteCode = data?.organization_code;
                    SetInsCode(data?.organization_code);
                    breadcumInfo[1].value = data?.name_of_school;
                }
            }
        } catch (error) {

        }

    };


    return (
        <div className="mt-4">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <Breadcum breadcumList={breadcumInfo} margin={"classic"} />
                    <SchoolUserPayments schoolCode={schoolsCode} instituteCode={insCode} cancelURL={`${UI_BASE_URL}/dashboard/school/${id}/${schoolsCode}/payment/list`} redirectURL={`${UI_BASE_URL}/dashboard/school/${id}/${schoolsCode}/payment/list`} />
                </div>}
        </div>
    )
};