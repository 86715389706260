import React, { useEffect, useRef, useState } from "react";
import FormBuilder from "../../../components/form-builder";
import { GET_STUDENTS_BY_STANDARD_DIVISION } from "../../../app/service/users.service";
import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { Breadcum } from "../../../components/breadcum";
import { GET_SCHOOL_BY_ID, GET_SCHOOL_BY_SCHOOL_CODE, GET_STANDARD_DIVISION_BY_SCHOOL_CODE } from "../../../app/service/school.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from "../../../app/service/payment-service";
import { GET_MODULE_BY_UUID, UPDATE_ASSIGN_USER } from "../../../app/service/module.service";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../app/service/subscription.service";
import { CREATE_MNS_USER_ASSIGN } from "../../../app/service/mns-service";
import { APPLY_COUPON_CODE, FECTH_PROMOCODES_BY_PROMOTIONS_FILTERS, UPDATE_APPLIED_COUPON_CODE, UPDATE_PROMO_CODES } from "../../../app/service/promotions";
import { UI_BASE_URL } from "../../../config/constant";
import { CREATE_CCAVENUE_PAYMENT } from "../../../app/service/ccavenue.service";
import moment from "moment";
import { SchoolUserPayments } from "../../../components/payments/school-users";

export const SchoolProfileStudentPayments: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [insCode, SetInsCode] = useState<any>("");
    let { schoolCode } = useParams<{ schoolCode: string }>();
    const schoolsCode = schoolCode;
    let instituteCode: any = "";

    useEffect(() => {
        fetchAllData();
    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            await getSchoolInfo();
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    };
    const getSchoolInfo = async () => {
        try {
            const res: any = await GET_SCHOOL_BY_SCHOOL_CODE(schoolsCode)
            if (res.status === "fail") {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res.rows[0];
                    instituteCode = data?.organization_code;
                    SetInsCode(data?.organization_code);
                    setSchoolInfo(data);
                }
            }
        } catch (error) {

        }
    };

    return (
        <div className="mt-4">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to={`/school-dashboard/profile/teacher/list/${schoolsCode}`}><span className='pe-2'>{schoolInfo?.name_of_school}</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/school-dashboard/profile/payment/list/${schoolsCode}`}><span>Payments</span></Link></li> /
                            <li className="breadcrumb-item "><span>Annual Membership Amount</span></li>
                        </ol>
                    </nav>
                    <SchoolUserPayments schoolCode={schoolsCode} instituteCode={insCode} dashBoard={"school"} cancelURL={`${UI_BASE_URL}/school-dashboard/profile/payment-list/${schoolsCode}`} redirectURL={`${UI_BASE_URL}/school-dashboard/profile/payment-list/${schoolsCode}`} />
                </div>}
        </div>
    )
};