import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_USER_USER_CODE } from "../../../app/service/users.service";

export const StudentDashboardView = () => {
  const history = useHistory();
  const [moduleData, setModuleData] = useState<any>([]);
  let { usercode, studentCode } = useParams<{
    usercode: string;
    studentCode: string;
  }>();
  const [userRole, setUserRole] = useState<any>();
  const userCode = usercode;
  const studentsCode = studentCode;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userRole = localStorage.getItem("user_role");
    setUserRole(userRole);
    fetchAllData();

  }, []);
  const fetchAllData = async () => {
    setLoading(true);
    try {
      const userDetails = await getUserDetails();
      await getModulesByUserCode(userDetails)
      setLoading(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const getUserDetails = async () => {
    try {
      const response: any = await GET_USER_USER_CODE(userCode);
      if (response.status === 'fail') {
        toast.error(response.message);
        throw new Error(response.message);
      } else {
        const data = response.rows[0];
        return data;
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      throw error;
    }
  };

  const getModulesByUserCode = async (userDetails: any) => {
    const res: any = await GET_MODULES_BY_USER_CODES(userDetails?.user_code);
    if (res) {
      if (res.status === "fail") {
        toast.error(res.message);
      } else {
        if (userDetails?.subscription_status === "SUBSCRIBED") {
          setModuleData(res);
        }
      }
    }
  };

  function onClickDetails(data: any) {

    if (data.module_status !== "completed") {
      history.push(`/dashboard/questions/${data.uuid}/${data.module_code}`);
      sessionStorage.setItem("childUserCode", studentsCode);
    } else {
      toast.error("Survey is Completed");
    }
  }

  function onClickDetailsParent(data: any, isType: string) {
    if (isType === "Father") {
      if (
        (data?.parent_details?.father?.module_status === "completed"
          ? false
          : true) &&
        data?.parent_details?.father?.user_code === userCode
      ) {
        sessionStorage.setItem("childUserCode", studentsCode);
        history.push(
          `/dashboard/questions/${data?.parent_details?.father?.user_code}/${data.module_code}`
        );
      } else {
        if (data?.parent_details?.father?.module_status === "completed") {
          toast.success("Survey is completed");
        } else {
          toast.error("Survey is not completed");
        }
      }
    } else if (isType === "Mother") {
      if (
        (data?.parent_details?.mother?.module_status === "completed"
          ? false
          : true) &&
        data?.parent_details?.mother?.user_code === userCode
      ) {
        sessionStorage.setItem("childUserCode", studentsCode);
        history.push(
          `/dashboard/questions/${data?.parent_details?.mother?.user_code}/${data.module_code}`
        );
      } else {
        if (data?.parent_details?.mother?.module_status === "completed") {
          toast.success("Survey is completed");
        } else {
          toast.error("Survey is not completed");
        }
      }
    } else {
      if (
        (data?.module_status === "completed" ? false : true) &&
        data?.user_code === userCode
      ) {
        sessionStorage.setItem("childUserCode", studentsCode);
        history.push(
          `/dashboard/questions/${data?.user_code}/${data.module_code}`
        );
      } else {
        if (data?.module_status === "completed") {
          toast.success("Survey is completed");
        } else {
          toast.error("Survey is not completed");
        }
      }
    }
  }

  function onClickViewReports(item: any) {
    history.push(`/dashboard/reports/${item.id}/${item.uuid}/${userCode}`);
  }

  function onClickMathsViewReport(item: any) {
    history.push(`/dashboard/maths/reports/student/${userCode}`);
  }

  const handleBack = () => {
    history.goBack();

    if (userRole === "CHAIRMAN" || userRole === "GENERAL_SECRETARY" || userRole === "INSTITUTE_ADMIN") {

      history.goBack();

    }

  };

  return (
    <div className="border-top border-primary py-3">
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
      {!loading && <div>
        <div className="row mb-3">
          <div className="col-md-10">
            <h2 style={{ paddingLeft: "30px" }}>Module Reports</h2>
          </div>
          <div className="col-md-1">
            <button
              className="btn btn-primary btn-sm px-5  rounded-12 cursor-pointer"
              onClick={handleBack}
            >
              Back
            </button>
          </div>
        </div>
        <div className="container-fluid">
          {moduleData.length === 0 && (
            <h2 style={{ textAlign: "center" }}>Modules are not Assigned</h2>
          )}
          <div className="row">
            {moduleData.map((module: any, i: number) => (
              <div className="row">
                <div className="col-md-5" key={i}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card info-card-1 shadow  mb-5 rounded mb-4">
                        <img className="card-img-top" />
                        <div className="card-body text-center">
                          <div className="row">
                            <div className="col-md-6">
                              <div
                                className="card-title"
                                style={{ fontWeight: "bold" }}
                              >
                                Module Name: {module.module_code}
                              </div>
                              <div className="card-title">
                                Student Name: {module?.user_details?.name}
                              </div>
                              <div className="card-title">
                                School Name:{" "}
                                {module?.user_details?.name_of_school}
                              </div>
                              <div className="card-title">
                                Status : {module.module_status}
                              </div>
                            </div>
                            {(module.module_code === "CAS" || module.module_code === "CAS-PARENT") && (
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <a
                                    className="text-secondary fw-bold text-decoration-none ms-2  rounded-org-12"
                                    style={{
                                      color: "black",
                                      width: "155px",
                                      fontSize: "20px",
                                    }}
                                  >
                                    Survey status
                                  </a>
                                </div>
                                {userRole === "PARENT" &&
                                  module.module_code === "CAS-PARENT" && (
                                    <a
                                      className="btn btn-primary text-white cursor-pointer"
                                      style={{ backgroundColor: "black" }}
                                      onClick={() => {
                                        onClickDetails(module);
                                      }}
                                    >
                                      Take Survey
                                    </a>
                                  )}
                                {module?.user_details?.cas_report_view === 1 &&
                                  module.module_code !== "CAS-PARENT" &&
                                  module.module_status !== "inprogress" &&
                                  module?.user_details?.type !== "individual" &&
                                  userRole !== "SUPER_ADMIN" && (
                                    <a
                                      className="btn btn-primary text-white cursor-pointer"
                                      style={{
                                        backgroundColor: "#0d6efd",
                                        width: "135px",
                                      }}
                                      onClick={() => {
                                        onClickViewReports(module);
                                      }}
                                    >
                                      Survey Reports
                                    </a>
                                  )}
                                {userRole === "SUPER_ADMIN" &&
                                  module?.user_details?.type !== "individual" &&
                                  module.module_status === "completed" && (
                                    <a
                                      className="btn btn-primary text-white cursor-pointer"
                                      style={{
                                        backgroundColor: "#0d6efd",
                                        width: "135px",
                                      }}
                                      onClick={() => {
                                        onClickViewReports(module);
                                      }}
                                    >
                                      Survey Reports
                                    </a>
                                  )}
                                {module?.user_details?.type === "individual" &&
                                  module.module_status === "completed" && (
                                    <a
                                      className="btn btn-primary text-white cursor-pointer"
                                      style={{
                                        backgroundColor: "#0d6efd",
                                        width: "135px",
                                      }}
                                      onClick={() => {
                                        onClickViewReports(module);
                                      }}
                                    >
                                      Survey Reports
                                    </a>
                                  )}
                                <div className="mt-2">
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      background:
                                        module?.module_status === "completed"
                                          ? "green"
                                          : "red",
                                      color:
                                        module?.module_status === "completed"
                                          ? "black"
                                          : "white",
                                      width: "135px",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      onClickDetailsParent(module, "student");
                                    }}
                                  >
                                    Student
                                  </a>
                                </div>
                                <div className="mt-2">
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      background:
                                        module?.parent_details?.father
                                          ?.module_status === "completed"
                                          ? "green"
                                          : "red",
                                      color:
                                        module?.parent_details?.father
                                          ?.module_status === "completed"
                                          ? "black"
                                          : "white",
                                      width: "135px",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      onClickDetailsParent(module, "Father");
                                    }}
                                  >
                                    Father
                                  </a>
                                </div>
                                <div className="mt-2">
                                  <a
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      background:
                                        module?.parent_details?.mother
                                          ?.module_status === "completed"
                                          ? "green"
                                          : "red",
                                      color:
                                        module?.parent_details?.mother
                                          ?.module_status === "completed"
                                          ? "black"
                                          : "white",
                                      width: "135px",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      onClickDetailsParent(module, "Mother");
                                    }}
                                  >
                                    Mother
                                  </a>
                                </div>
                              </div>
                            )}
                            {(module.module_code !== "CAS-PARENT" && module.module_status === "completed") && (
                              <div className="col-md-6">
                                <div className="pt-5">
                                  <button
                                    className="btn btn-primary text-white cursor-pointer"
                                    style={{
                                      background: "#0d6efd",
                                      border: "none",
                                    }}
                                    onClick={() => {
                                      onClickMathsViewReport(module);
                                    }}
                                  >
                                    View Report
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      }
    </div>
  );
};
