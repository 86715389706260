import React from 'react'
import { Switch, Route } from 'react-router-dom';
import SchoolActiveStudents from '../../pages/school-dashboard/dashboard/active-students/list';
import CasClassWiseAnalysys from '../../pages/school-dashboard/dashboard/cas-analysys/classwise';
import CasAnalysys from '../../pages/school-dashboard/dashboard/cas-analysys/view';
import MathsClassWiseAnalysys from '../../pages/school-dashboard/dashboard/maths-analysys/classwise';
import MathsAnalysys from '../../pages/school-dashboard/dashboard/maths-analysys/view';
import SchoolRegisteredStudents from '../../pages/school-dashboard/dashboard/registered-students/list';
import SchoolDashboard from '../../pages/school-dashboard/dashboard/view';
import SchoolProfilePage from '../../pages/school-dashboard/profile';
import MthsAnalysys from '../../pages/school-dashboard/view-report/view';
import SchooldashboardFreeTrial from '../../pages/school-dashboard/profile/free-trial/form';



export const SchoolDashBoardRoutes = () => {
  return (
    <Switch>
      <Route exact path="/school-dashboard/view" component={SchoolDashboard}></Route>
      <Route exact path="/school-dashboard/view/registered-studentslist/list" component={SchoolRegisteredStudents}></Route>
      <Route exact path="/school-dashboard/view/active-studentslist/list" component={SchoolActiveStudents}></Route>
      <Route exact path="/school-dashboard/view/cas-analysys/classwise/:className" component={CasClassWiseAnalysys}></Route>
      <Route exact path="/school-dashboard/view/cas-analysys/view" component={CasAnalysys}></Route>
      <Route exact path="/school-dashboard/view/maths-analysys/classwise/:className" component={MathsClassWiseAnalysys}></Route>
      <Route exact path="/school-dashboard/view/maths-analysys/view" component={MathsAnalysys}></Route>
      <Route exact path="/school-dashboard/view-report/view" component={MthsAnalysys}></Route>
      <Route path="/school-dashboard/profile" component={SchoolProfilePage}></Route>
      <Route exact path="/school-dashboard/freetrial/form/:id/:schoolCode" component={SchooldashboardFreeTrial}></Route>


    </Switch>
  )
}
