import React, { useEffect, useState } from "react";
import { GET_USER_USER_CODE } from "../../../app/service/users.service";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import appConfig from "../../../config/constant";
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'

const TeacherViewProfile = () => {
    const [userInfo, setUserInfo] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const UserCode = localStorage.getItem("user_code");

    useEffect(() => {
        getStudentDetails();
    }, []);
    const getStudentDetails = async () => {
        setLoading(true);
        try {
            const response: any = await GET_USER_USER_CODE(UserCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                setLoading(false);
            } else {
                const data = response.rows[0];
                if (data.date_of_birth) {
                    const date_of_birth: any = new Date(data.date_of_birth);
                    // setStartDate(date_of_birth);
                }
                setUserInfo(data);
                setLoading(false);

            }

        } catch (error) {

        }
    }

    const getTimeFormate = (dateTimeString: any) => {
        const formattedDate = new Date(dateTimeString).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        return formattedDate;
    }
    const getStandard = (standard: string): number | undefined => {
        switch (standard) {
            case "SIX":
                return 6;
            case "SEVEN":
                return 7;
            case "EIGHT":
                return 8;
            case "NINE":
                return 9;
            case "TEN":
                return 10;
            case "ELEVEN":
                return 11;
            case "TWELVE":
                return 12;
            default:
                return undefined;
        }
    };
    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><span> Profile</span></li>
                        </ol>
                    </nav>
                    <div className='ps-2  mt-4  ms-lg-5'>
                        <div className="row">
                            <div className="col-12   mb-4">
                                <div className="d-md-flex sc-profile-details">
                                    <div className="sc-profile-img py-0">
                                        <img className="pr-img_logo"
                                            src={userInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${userInfo?.profile_img.charAt(0) === "/" ? userInfo?.profile_img.substring(1) : userInfo?.profile_img}` : STUDENTIMG}
                                        />
                                    </div>
                                    <div className="sc-profile-text ps-md-4">
                                        <h2 className="mt-0 mb-2">{userInfo?.name && userInfo?.name?.charAt(0).toUpperCase() + userInfo?.name?.slice(1)}</h2>
                                        <p className="m-0 sc-profile-contact">
                                            <span>{userInfo?.mobile_number}</span> {userInfo?.email_id && <span> | </span>} <span>{userInfo?.email_id} </span>
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            {userInfo?.teacher_type === "CLASS_TEACHER" && <span className="f16"> <span>Class Teacher : </span>{userInfo?.class_section_details?.standard}<sup>th</sup>  - <span>{userInfo?.class_section_details?.section}</span></span>}
                                        </p>
                                        <p className="m-0 sc-profile-addrs">
                                            <span className="f16"> <span>Subject Teacher : </span>
                                                {userInfo?.teaching_standards?.map((standard: any, i: number) => (
                                                    <>
                                                        <span key={i}> {getStandard(standard?.label)}<sup>th</sup> </span> -
                                                        {standard?.divisions?.map((division: any, j: number) => (
                                                            <span key={j}>{division?.value}{j < standard?.divisions?.length - 1 && ","}</span>
                                                        ))}
                                                    </>
                                                ))}
                                                (  <span>{userInfo?.teaching_subjects?.map((subjects: any, z: number) => (
                                                    <span key={z}>{subjects?.value}{z < userInfo.teaching_subjects.length - 1 && ","} </span>
                                                ))}</span>)
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6   mb-4">
                                <label className="f12 text-mgray">Name</label>
                                <p className="f18 mb-0">{userInfo?.name}</p>
                            </div>
                            <div className="col-md-4 col-sm-6   mb-4">
                                <label className="f12 text-mgray">User ID</label>
                                <p className="f18 mb-0">{userInfo?.user_name}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Date of Birth</label>
                                <p className="f18 mb-0">{userInfo?.date_of_birth && getTimeFormate(userInfo?.date_of_birth)}</p>
                            </div>

                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Gender</label>
                                <p className="f18 mb-0">{userInfo?.gender}</p>
                            </div>

                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Mobile Number</label>
                                <p className="f18 mb-0">{userInfo?.mobile_number}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">WhatsApp Number</label>
                                <p className="f18 mb-0">{userInfo?.whatsapp_number}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Email ID</label>
                                <p className="f18 mb-0">{userInfo?.email_id}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Blood Group</label>
                                <p className="f18 mb-0">{userInfo?.blood_group}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Date of Registration</label>
                                <p className="f18 mb-0">{userInfo?.date_of_registration && getTimeFormate(userInfo?.date_of_registration)}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Address</label>
                                <p className="f18 mb-0">{userInfo?.address}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">State</label>
                                <p className="f18 mb-0">{userInfo?.state}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">District</label>
                                <p className="f18 mb-0">{userInfo?.district}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">City/Village</label>
                                <p className="f18 mb-0">{userInfo?.city_village}</p>
                            </div>
                            <div className="col-md-4 col-sm-6  mb-4">
                                <label className="f12 text-mgray">Pincode</label>
                                <p className="f18 mb-0">{userInfo?.pin_code}</p>
                            </div>

                        </div>
                    </div>
                </div>}
        </div>
    )
}
export default TeacherViewProfile;