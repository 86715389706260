import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { GET_SCHOOL_BY_SCHOOL_CODE } from "../../../app/service/school.service";
import { SchoolUserPayments } from "../../../components/payments/school-users";
import { UI_BASE_URL } from "../../../config/constant";

export const InstituteProfileStudentPayments: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [schoolInfo, setSchoolInfo] = useState<any>({});
    const [insCode, SetInsCode] = useState<any>("");
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    let instituteCode: any = "";

    useEffect(() => {
        fetchAllData();
    }, []);
    const fetchAllData = async () => {
        setLoading(true);
        try {
            await getSchoolInfo();
            setLoading(false);

        } catch (error) {
            setLoading(false);
        }
    };

    const getSchoolInfo = async () => {
        try {
            const res: any = await GET_SCHOOL_BY_SCHOOL_CODE(schoolsCode)
            if (res.status === "fail") {
                toast.error(res.message);
            } else {
                if (res) {
                    const data = res.rows[0];
                    instituteCode = data?.organization_code;
                    SetInsCode(data?.organization_code);
                    setSchoolInfo(data);
                }
            }
        } catch (error) {

        }
    };

    return (
        <div className="mt-4">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to={`/institute-dashboard/manage-school/list`}><span className='pe-2'>{schoolInfo?.name_of_school}</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/institute-dashboard/manage-school/list/info/${id}/paymentlist/${schoolsCode}`}><span>Payments</span></Link></li> /
                            <li className="breadcrumb-item "><span>Annual Membership Amount</span></li>
                        </ol>
                    </nav>
                    <SchoolUserPayments schoolCode={schoolsCode} instituteCode={insCode} dashBoard={"school"} cancelURL={`${UI_BASE_URL}/institute-dashboard/manage-school/list/${id}/payment-list/${schoolsCode}`} redirectURL={`${UI_BASE_URL}/institute-dashboard/manage-school/list/${id}/payment-list/${schoolsCode}`} />
                </div>}
        </div>
    )
};