import { Switch, Route, Redirect } from "react-router";
import SubscriptionInfo from "./subscription-info";



interface Props {
    match?: any;
}
const SubscriptionRoutes = (props: Props) => {

    let url: string | undefined = props.match?.url;
    if (url?.endsWith('/')) {
        url = url.substr(0, url.length - 1);
    }


    return (
        <Switch>
            <Route path={`${url}/info/list`} exact>
                <SubscriptionInfo></SubscriptionInfo>
            </Route>
            <Route path={`${url}/info/dormant`} exact>
                <SubscriptionInfo></SubscriptionInfo>
            </Route>
            <Route path={`${url}/info/reminder-days`} exact>
                <SubscriptionInfo></SubscriptionInfo>
            </Route>
        </Switch>
    );
}
export default SubscriptionRoutes;